import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./archivio-detail-filter-and-results.css";
import ArchivioDetailFilterItem from "./archivio-detail-filter-item";
import {deleteArticleFromFolderRequest} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import WebSearchResultList from "./web-search-result-list";

const ArchivioDetailFilterAndResults = (props) => {
	const translations = {
		en: {
		 
		  'ppps1segnala': "No articles in the folder",
	   
		},
		it: {
	
	  
		  'ppps1segnala': 'Nessun articolo nella cartella',
	
		},
	  };
	  function translate(key, lang) {
		const italianTranslations = translations.it;
	  
		if (lang === 'it' && italianTranslations[key]) {
		  return italianTranslations[key];
		}
	  
		return translations.en[key];
	  }
	  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
	
	  const ppps1segnal = translate('ppps1segnala', lang);
	const [list, setList] = useState([]);
	const dispatch = useDispatch();
	const {auth, folder} = useSelector((state) => state);

	useEffect(() => {
		setList(props.list);
	}, [JSON.stringify(props.list)]);

	const handleDeleteArticleFromFolderRequest = (data) => {
		const cbSuccess = async (response) => {
			setList((prev) => [
				...list.filter(
					(item) =>
						item?.description !== data?.description &&
						item?.title !== data?.title &&
						item?.pubDate !== data?.pubDate
				),
			]);
			alert(response.message);
		};
		const cbFailure = (error) => {
			alert(error.message);
		};

		if (auth?.data?.token)
			dispatch(
				deleteArticleFromFolderRequest(
					data,
					auth?.data?.token,
					cbSuccess,
					cbFailure
				)
			);
	};

	return (
		<div
			className={`archivio-detail-filter-and-results-archivio-detail-filter-and-results ${props.rootClassName} `}
		>
			{/* <div className="archivio-detail-filter-and-results-group96">
        <span className="archivio-detail-filter-and-results-text">
          <span className="archivio-detail-filter-and-results-text01">
            Archivio &lt;
	          <span
		          dangerouslySetInnerHTML={{
			          __html: " ",
		          }}
	          />
          </span>
          <span className="">Nome cartella</span>
        </span>
				<div className="archivio-detail-filter-and-results-frame1">
          <span className="archivio-detail-filter-and-results-text03">
            <span className="">Tipologia di link</span>
          </span>
					<img
						src="/playground_assets/vector24491-i7q.svg"
						alt="Vector24491"
						className="archivio-detail-filter-and-results-vector2"
					/>
				</div>
			</div> */}
			<div style={{marginTop:'-40px', marginBottom:'400px', marginLeft:'-13px' }} className="archivio-detail-filter-and-results-group95">
				{list?.length === 0 && (
					<div className={"no-data-found"}>{ppps1segnal}</div>
				)}
				{list?.map((item, index) => {
					return (
						item.type === 'web' ? <WebSearchResultList
						handleDeleteArticleFromFolderRequest={
							handleDeleteArticleFromFolderRequest
						}
						folder_id={props.folder?._id}
						index={index}
					
							{...item}
							rootClassName="web-search-result-list-root-class-name1"
						
						/> : <ArchivioDetailFilterItem
							handleDeleteArticleFromFolderRequest={
								handleDeleteArticleFromFolderRequest
							}
							folder_id={props.folder?._id}
							index={index}
							{...item}
						/>


					);
				})}
			</div>
		</div>
	);
};

ArchivioDetailFilterAndResults.defaultProps = {
	rootClassName: "",
};

ArchivioDetailFilterAndResults.propTypes = {
	rootClassName: PropTypes.string,
};

export default ArchivioDetailFilterAndResults;
