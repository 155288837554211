
import React, { useState, useContext } from "react";

import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import SideBar from "../components/side-bar";
import "./search-con-prova.css";
import ProfileSide from "../components/profile-side";
import Hamburgher from "../components/hamburgher-menu";
import SearchBar2 from "../components/search-bar2";
import { AuthContext } from "../context/authContext";
import { useDispatch, useSelector } from "react-redux";

const SearchConProva = (props) => {
 
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const history = useHistory();
  const { auth, folder } = useSelector((state) => state);


  const translations = {
    en: {
      'search-con-prova-text01': 'Weagle the first enterprise',
'search-con-prova-text02': 'search engine',
'search-con-prova-text03': ' ',
'search-con-prova-text04': '1 demo search available',
      'search-con-prova-text08': 'Professional searches made simpler and more efficient thanks to the innovative navigation tools of Power Search.',
      'search-con-prova-text09': 'Delete, archive and discover how to monetize the data generated by your browsing.',
      'search-con-prova-text10': 'Harness the potential of AI while keeping your privacy protected',
    },
    it: {

      'search-con-prova-text01': 'Weagle il primo motore di ricerca',
      'search-con-prova-text02': 'per il mondo enterprise', 
      'search-con-prova-text03': ' ',
      'search-con-prova-text04': '1 ricerca demo disponibile',
      'search-con-prova-text08': 'Ricerche professionali più semplici e performanti grazie agli innovativi tools di navigazione del Power Search',
      'search-con-prova-text09': "Sfrutta le potenzialità dell'AI mantenendo protetta la tua privacy ",
      'search-con-prova-text10': "Proteggi i tuoi dati di navigazione all'interno del tuo Data Wallet."
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01 = translate('search-con-prova-text01', lang);
  const text02 = translate('search-con-prova-text02', lang);
  const text03 = translate('search-con-prova-text03', lang);
  const text04 = translate('search-con-prova-text04', lang);
  const text08 = translate('search-con-prova-text08', lang);
  const text09 = translate('search-con-prova-text09', lang);
  const text10 = translate('search-con-prova-text10', lang);
  return (
    <div className="search-con-prova-container">
      <Helmet>
        <title>Weagle</title>
        <meta property="og:title" content="Weagle" />
      </Helmet>
      <div className="search-con-prova-container-principale">
        <div className="search-con-prova-container1">
          <SideBar rootClassName="side-bar-root-class-name" /> 
     
        </div> 
      
        <div className="menudesktop">
        <div className="nomenurightprofile">
    <ProfileSide  />

    </div>
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      
      </div>

      <div className="search-con-prova-naviga-trapiu-div">
        <div className="search-con-prova-naviga-trapiu-div-c1">
          <h1 className="search-con-prova-text03">
            <span> {text01}  <br></br>  {text02}
</span>
            <br></br>
            {/* <span> {text03}</span> */}
          </h1>
          
        </div>
        <SearchBar2/>
      </div>
      
      {!auth?.data?.token ? (
      <span className="search-con-prova-demoresearchp">
    {/* {text04} */}
      </span> 
) : (
  <span></span>
)}  
      <div className="search-con-prova-iconsdiv">
        <div className="search-con-prova-feature1">
          <img
            alt="image"
            src="/playground_assets/shield-user-protection%201.svg"
            className="search-con-prova-image"
            
          />
          <span className="search-con-prova-text07">
         {text10}
          </span>
        </div>

        <div className="search-con-prova-feature2">
          <img
            alt="image"
            src="/playground_assets/earth%2C%20home%2C%20world%201.svg"
            className="search-con-prova-image1"
            style={{marginTop:'35px'}}
          />
          <span className="search-con-prova-text08">
          {text08}
          </span>
        </div>

        <div className="search-con-prova-feature3">
          <img
            alt="image"
            src="/playground_assets/password-edit-protection%201.svg"
            className="search-con-prova-image2"
            style={{marginTop:'18px'}}
          />
          <span className="search-con-prova-text09">
          {text09}
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchConProva;
