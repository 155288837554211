import React, {useState} from "react";

import PropTypes from "prop-types";

import "./profile-side.css";
import { useSelector } from "react-redux";
import BottoneGenerico1 from "./bottone-generico1";
import SignUpPopUp from "./sign-up-pop-up";
import LogoutPopUp from "./logout-pop-up";

const ProfileSide = (props) => {
  const [showPopup, setShowPopup] = useState(false)
  const { auth, showSingUp } = useSelector((state) => state);
  
  if (auth?.data?.email) {
    return (
      <div
        className={`profile-side-container ${props.rootClassName} `}
      onClick={()=>{
        setShowPopup(prev => !prev)
      }}
      >
        <div className="profile-side-profile-name-div">
        <span className="profile-side-name-surname">
  {(() => {
    const firstName = auth?.data?.first_name || '';
    const lastName = auth?.data?.last_name || '';
    const fullName = `${firstName} ${lastName}`;
    
    if (fullName.length > 16) {
      return firstName.length > 8 ? firstName.substring(0, 8) : firstName;
    }
    return firstName;
  })()}
</span>
{/* <span className="profile-side-name-surname">
  {(() => {
    const firstName = auth?.data?.first_name || '';
    const lastName = auth?.data?.last_name || '';
    const fullName = `${firstName} ${lastName}`;
    
    if (fullName.length > 16) {
      return lastName.length > 8 ? lastName.substring(0, 8) : lastName;
    }
    return lastName;
  })()}
</span> */}

          {/* <span className="profile-side-username">{auth?.data?.email}</span> */}
        </div>
        <div className="profile-side-username-container">
  <div className="profile-side-avatar-container">
    {auth?.data?.image ? (
      <img className="profile-side-profile-pic" src={auth?.data?.image} alt="" />
    ) : (
      <div className="profile-side-profile-pic" />
    )}
  </div>
  <div className="profile-side-username-wrapper">
    <span style={{color:'white'}} className="profile-side-username">
    {auth?.data?.email.split('@')[0].slice(0, 13) + (auth?.data?.email.split('@')[0].length > 13 ? '..' : '')}
    <br />
    </span>
  </div>
</div>


        {
          showPopup && <LogoutPopUp setShowPopup={setShowPopup} />
        }

      </div>
      
    );
  } else {
    return (
      <>
        <BottoneGenerico1 />
        {showSingUp && (
          <div className="signUp">
            <SignUpPopUp />
          </div>
        )}
      </>
    );  
  } 
};

ProfileSide.defaultProps = {
  ProfileName: "Andrea Beneventi",
  ProfileName1: "@andreabeneventi12",
  rootClassName: "",
};

ProfileSide.propTypes = {
  ProfileName: PropTypes.string,
  ProfileName1: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default ProfileSide;
