// web-search-result-list.js
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { SearchContext } from "../context/search_context";
import "./web-search-result-list.css";
import PopUpSaveLink1 from "./pop-up-save-link1";
import { useLocation } from 'react-router-dom';

const WebSearchResultList = (props) => {
  const location = useLocation();
  const [showPicker, setShowPicker] = useState(false);
  const { setShowFolderModal, setData } = props;
  const protafolioObj = {
    title: props?.title,
    description: props?.description,
    link: props?.link,
    pubDate: props?.pubDate,
    source: props?.source,
  };
  const { handleAddArticleToPortafolioDati } = useContext(SearchContext);

  const { handleAttemptedAccess } = useContext(SearchContext);

  const openLink = () => {
    if (props.blocked) {
      handleAttemptedAccess(props.link);
    } else {
      handleAddArticleToPortafolioDati(protafolioObj);
      window.open(props.link);
    }
  };

  const isArchivioDettaglioRoute = location.pathname === '/archivio-dettaglio';
  const onClick = isArchivioDettaglioRoute
    ? (event) => {
        event.stopPropagation();
        if (!props.blocked) {
          props.handleDeleteArticleFromFolderRequest(props);
        }
      }
    : (event) => {
        event.stopPropagation();
        if (!props.blocked) {
          setData(protafolioObj);
          setShowPicker((prev) => !prev);
        }
      };

  const imgSrc = isArchivioDettaglioRoute ? "/playground_assets/cancel.png" : props?.image_src;
  const imgAlt = isArchivioDettaglioRoute ? "Vector5961" : props?.image_alt;

  const getRadialColors = (score) => {
    if (score <= 25) {
      return `conic-gradient(green ${score}%, #E0E5DF ${score}%)`;
    } else if (score <= 50) {
      return `conic-gradient(green 25%, yellow ${score - 25}%, #E0E5DF ${score}%)`;
    } else if (score <= 75) {
      return `conic-gradient(green 25%, yellow 25%, orange ${score - 50}%, #E0E5DF ${score}%)`;
    } else {
      return `conic-gradient(green 25%, yellow 25%, orange 25%, red ${score - 75}%, #E0E5DF ${score}%)`;
    }
  };

  return (
    <div className={`web-search-result-list-container ${props.rootClassName} `}>
      <div
        style={{
          cursor: props.blocked ? "not-allowed" : "pointer",
          backgroundImage: props.blocked ? "url('/playground_assets/locktool.svg')" : "none",
          backgroundSize: "120px 120px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="web-search-result-list-web-result-main-div"
      >
        <div className="web-search-result-list-web-result-link-title-description-div" onClick={openLink}>
          <div className="web-search-result-list-web-result-title-container">
            <span className="web-search-result-list-web-result-title">
              {props.title}
            </span>
          
          </div>
          <span className="web-search-result-list-web-result-link">
            {props.link.slice(0, 40)}
            {props.link.length > 40 && "..."}
          </span>
          <span className="web-search-result-list-web-result-description">
            {props.description.slice(0, 150)}
            {props.description.length > 150 && "..."}
          </span>
        </div>
        <div className="web-search-result-list-web-result-threat-score-container">
              <div
                className="web-search-result-list-web-result-threat-score-radial"
                style={{ background: getRadialColors(props.threatScore) }}
              >
                <div className="web-search-result-list-web-result-threat-score-hole">
                  <span className="web-search-result-list-web-result-threat-score-text">{props.threatScore}</span>
                </div>
              </div>
            </div>
        <div
          className="web-search-result-list-web-result-share-icon-div"
          onClick={onClick}
          style={{ cursor: props.blocked ? "not-allowed" : "pointer" }}
        >
          <img
            alt={imgAlt}
            src={imgSrc}
            className="web-search-result-list-share-icon"
          />

          {showPicker && !props.blocked && (
            <PopUpSaveLink1
              source={props?.source.text}
              url={props?.source?.url}
              setShowFolderModal={setShowFolderModal}
              setShow={setShowPicker}
              link={props.link}
            />
          )}
        </div>
      </div>
    </div>
  );
};

WebSearchResultList.defaultProps = {
  title: "-",
  rootClassName: "",
  image_alt: "image",
  description: "-",
  image_src: "/playground_assets/dots 1.svg",
  link: "https://www.asromalive.it/",
  blocked: false,
  threatScore: 0,
};

WebSearchResultList.propTypes = {
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  description: PropTypes.string,
  image_src: PropTypes.string,
  link: PropTypes.string,
  blocked: PropTypes.bool,
  threatScore: PropTypes.number,
};

export default WebSearchResultList;
