import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import Loader from "../components/loader";
import { SearchContext } from "../context/search_context";
import { useHistory } from "react-router-dom";
import Hamburgher from "../components/hamburgher-menu";
const AziendeDetails = (props) => {
  let history = useHistory();
  const {detail} = history.location.state;
  const { loader, setTab } =
    useContext(SearchContext);
  useEffect(() => {
    setTab("aziende");
  });
  return (
    <div className="feed-container">
      <Helmet>
        <title>Aziende - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
        </div>
        <div className="power-search-container2">
          <SearchBar1 rootClassName="search-bar1-root-class-name" />
        </div>
      
        <div className="menudesktop">
        <div className="nomenurightprofile">
    <ProfileSide  />
    </div>
    
    </div>
      </div>
      <div className="power-search-tabs-filters-div">
        <TabPowerSearch
          type={"aziende"}
          rootClassName="tab-power-search-root-class-name"
        />
        
      </div>
      <div className="aziende-detail-container">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/aziende-search");
          }}
          className="feed-detail-back-button"
        >
          <span>&lt; indietro </span>
        </span>
      </div>
      {loader && <Loader />}

   
      <div className="feed-container8">
        <table className="table">
          {
            Object.keys(detail)?.map(item => {
              return  <tr>
                <th>{item}</th>
                <td>{detail[item]}</td>
              </tr>
            })
          }
        </table>
      </div>
    </div>
  );
};

export default AziendeDetails;
