import React from 'react'

import PropTypes from 'prop-types'
import { NavLink } from "react-router-dom";
import './side-bar.css'
import { useContext } from "react";
import { AuthContext } from "../context/authContext"; 
import { useHistory } from "react-router-dom";
import ChangeHomepageButton from './changeHomeButton';
import SegnalaProblema from './segnalaProblema';
import { useDispatch, useSelector } from "react-redux";
import ChatIcon from '../images/ChatIcon.svg';
import ProfileSide from './profile-side';
const openInNewTab = (url) => {


  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
} 
const SideBar = (props) => {
  const translations = {
    en: {
      'archivio': 'Archive',
    },
    it: {

      'archivio': 'Archivio',
      
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01Side = translate('archivio', lang);
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const history = useHistory();
  const { auth, folder } = useSelector((state) => state);
  let activeStyle = {
    backgroundColor: "white",
    color: "#000",
    transition: "1s",
  }; 
  
  return (
    <div className={`side-bar-container ${props.rootClassName} `}>
     
          <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="side-bar-logo" />
        <ProfileSide />
    <NavLink to="/power-search" activeClassName="active-background">
    <button style={{marginTop:0}} className={`sidebar-nav-item-dashboard`}>
          <img src="/playground_assets/iconaSaas_search.svg" alt="Accounts" style={{width:'28px', marginRight:'12px'}}  /> Power Search
        </button>
    
  </NavLink>
  {accountType === "free" ? (
 null
  ) : (
    <NavLink to="/chat" activeClassName="active-background">
 <button style={{marginTop:0}} className={`sidebar-nav-item-dashboard`}>
          <img src="/playground_assets/iconaSaas_chat.svg" alt="Accounts" style={{width:'28px', marginRight:'12px'}}  /> Weagle AI
        </button>
       
 
</NavLink>
    )
  }

{auth?.data?.token ? (

<NavLink to="/data-wallet" activeClassName="active-background">

  <button style={{marginTop:0}} className={`sidebar-nav-item-dashboard`}>
          <img src="/playground_assets/iconaSaas_wallet.svg" alt="Accounts" style={{width:'28px', marginRight:'12px'}}  /> Data Wallet
        </button>
     
</NavLink>

) : (
<span></span>
)}  
  <NavLink to="/feed" activeClassName="active-background">
  <button style={{marginTop:0}} className={`sidebar-nav-item-dashboard`}>
          <img src="/playground_assets/iconaSaas_digital.svg" alt="Accounts" style={{width:'28px', marginRight:'12px'}}  /> Digital Journal
        </button>
      
   
  </NavLink>

  <NavLink to="/archivio" activeClassName="active-background">
  <button style={{marginTop:0}} className={`sidebar-nav-item-dashboard`}>
          <img src="/playground_assets/iconaSaas_archivio.svg" alt="Accounts" style={{width:'28px', marginRight:'12px'}}  /> {text01Side}
        </button>
       
   
  </NavLink>


 {/* {accountType === "free" ? (
    <NavLink to="/welfare" activeClassName="active-background">
    <div className='side-bar-inactive'>
      <img
        alt={props.image_alt11}
        src={props.image_src11}
        className="side-bar-welfare-icon"
      />
      <span className="side-bar-power-search-text">Coupons</span>
    </div>
  </NavLink>
    ) : (
      <NavLink to="/welfare" activeClassName="active-background">
      <div className='side-bar-inactive'>
        <img
          alt={props.image_alt11}
          src={props.image_src11}
          className="side-bar-welfare-icon"
        />
        <span className="side-bar-power-search-text">Welfare</span>
      </div>
    </NavLink>
    )} */}

  
  {/* <NavLink style={{display:'none'}} to="/welfare" activeClassName="active-background">
      <div className='side-bar-inactive'>
      <img
          alt={props.image_alt11}
          src={props.image_src11}
          className="side-bar-welfare-icon"
        />
    <span className="side-bar-power-search-text">Welfare</span>
      </div>
   
  </NavLink> */}
  <div >
    <div className="segnalaemotoredivButton">
    <SegnalaProblema />  
<ChangeHomepageButton /> 
    </div>
 
  </div>


    </div>
   
  )
}

SideBar.defaultProps = {
  image_src1: '/playground_assets/messages-chat-text%201.svg',
  image_src12: '/playground_assets/lock-protection-checkmark%201.svg',
  Weagle: 'Weagle',
  image_alt13: 'image',
  image_src11: '/playground_assets/heart-checkmark%201.svg',
  image_alt11: 'image',
  image_alt12: 'image',
  image_alt: 'image',
  rootClassName: '',
  image_src13: '/playground_assets/bookmarks%201.svg',
  image_alt1: 'image',
  image_src: '/playground_assets/group201.svg',
}

SideBar.propTypes = {
  image_src1: PropTypes.string,
  image_src12: PropTypes.string,
  Weagle: PropTypes.string,
  image_alt13: PropTypes.string,
  image_src11: PropTypes.string,
  image_alt11: PropTypes.string,
  image_alt12: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src13: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src: PropTypes.string,
}

export default SideBar
