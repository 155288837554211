import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import Loader from "../components/loader";
import { SearchContext } from "../context/search_context";
import { useHistory } from "react-router-dom";
import Hamburgher from "../components/hamburgher-menu";
const link = [{ here: "h" }, { here: "h" }, { here: "h" }, { here: "h" }];
const AziendeListCard = (props) => {
  let history = useHistory();
  const { loader, azindeSearch, setTab } =
    useContext(SearchContext);
  useEffect(() => {
    setTab("aziende");
  });
  return (
    <div className="feed-container">
      <Helmet>
        <title>Aziende - Weagle</title>
        <meta property="og:title" content="AziendeSearch - Weagle" />
      </Helmet>
      <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2" />
        </div>
        <div className="power-search-container2">
          <SearchBar1 rootClassName="search-bar1-root-class-name" />
        </div>
        <div className="menudesktop">
        <div className="nomenurightprofile">
    <ProfileSide  />
    </div>
     
    </div>
      </div>
      <div className="power-search-tabs-filters-divNews">
      <div className="aziende-search-tabs-filters-div">
        <TabPowerSearch
          type={"aziende"}
          rootClassName="tab-power-search-root-class-name"
        />
      </div>
      </div>
      {loader && <Loader />}
      <div
        className={`data-wallet-enterprise-results-container aziende-width
      ${props.rootClassName} `}
      >
        <div className="data-wallet-enterprise-results-results-wallet-data aziende-width">
          <div className="data-wallet-enterprise-results-wallet-data-container aziende-width">
            {azindeSearch?.map((item) => {
              return (
                <div
                  className="data-wallet-enterprise-results-results-data-wallet-card aziende-width"
                  onClick={() => {
                    history.push({
                      pathname: "/aziende-detail",
                      state: { detail: item?.detail },
                    });
                  }}>
                  <div className="title aziende-width">
                    <div className="aziende-enterprise-results">
                      <div className="aziende-card">
                      <span className="aziende-city aziende-title">
    {item?.title.split(" ").slice(0, 15).join(" ")}
</span>

                        <span className="aziende-city">
                          {item?.address}
                        </span>
                        <span className="aziende-city">
                            {item?.iva}
                        </span>
                        <span className="aziende-city"
                        >
                           {item?.amount}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AziendeListCard;
