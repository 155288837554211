import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom"; // Use useHistory instead of useNavigate
import { AuthContext } from "../context/authContext";
import "./loginPlatformComponent.css";

const LoginPlatformComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authContext = useContext(AuthContext);
  const history = useHistory(); // Use useHistory here

  useEffect(() => {
    if (authContext.authState.isAuthenticated) {
      history.push("/dashboard"); // Use history.push for navigation
    }
  }, [authContext.authState.isAuthenticated, history]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await authContext.handlePlatformLogin(email, password);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <div className="login-platform-container">
      <form className="login-platform-form" onSubmit={handleSignIn}>
        <h2>Sign In</h2>
        <div className="login-platform-form-group">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="mail@example.com"
          />
        </div>
        <div className="login-platform-form-group">
          <label htmlFor="password">Password*</label>
          <input
            type="password"
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Min. 8 characters"
          />
        </div>
        {/* <div className="login-platform-form-group">
          <a href="#forgot-password" className="login-platform-forgot-password">
            Forgot password?
          </a>
        </div> */}
        <button type="submit" className="login-platform-submit-btn">Sign In</button>
      </form>
    </div>
  );
};

export default LoginPlatformComponent;
