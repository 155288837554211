import React from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import ProfileSide from "../components/profile-side";
import "./power-search.css";
import DataWalletEnterprise from "../components/data-wallet-enterprise";
import Hamburgher from "../components/hamburgher-menu";
import { useHistory } from "react-router-dom";
const DataWallet = (props) => {
  let history = useHistory();
  return (
    <div className="power-search-containerDataWallet">
      <Helmet>
        <title>Data Wallet - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"></SideBar>
        </div>
     
        <div className="menudesktop">
        <div className="nomenurightprofile">
    <ProfileSide  />
    </div>
    
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      </div>
      <div className="power-search-tabs-filters-div"></div>

      <div className="power-search-results-div" style={{ marginTop: -270 }}>
        <DataWalletEnterprise />
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default DataWallet;
