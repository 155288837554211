import React, { useContext } from "react";

import PropTypes from "prop-types";

import "./search-engine-selection.css";
import { SearchContext } from "../context/search_context";

const SearchEngineSelection = (props) => {
  const { handleEngineSelection, selectedEngines, loader } =
    useContext(SearchContext);
  return (
    <div
      className={`search-engine-selection-container ${props.rootClassName} `}
    >  <span className="search-engine-selection-text1">
    Powered by &nbsp;&nbsp;&nbsp;&nbsp;
  </span>
       <div className="search-engine-selection-bing">
        <input
          disabled={loader}
          value={"bing"}
          checked={selectedEngines.includes("bing")}
          onChange={(event) => {
            handleEngineSelection(event.target.checked, "bing");
          }}
          type="checkbox"
          className="search-engine-selection-checkbox2"
        />
        <img
          alt={props.image_alt2}
          src={props.image_src2}
          className="search-engine-selection-image2"
        />
        <span className="search-engine-selection-text2">{props.Bing}</span>
      </div>
       <div className="search-engine-selection-d-d-g">
        <input
          disabled={loader}
          value={"ddg"}
          checked={selectedEngines.includes("ddg")}
          onChange={(event) => {
            handleEngineSelection(event.target.checked, "ddg");
          }}
          type="checkbox"
          className="search-engine-selection-checkbox2"
        />
        <img
          alt={props.image_alt3}
          src={props.image_src3}
          className="search-engine-selection-image1"
        />
        <span className="search-engine-selection-text1">
          {props.DuckDuckGo}
        </span>
      </div>

     
     
      <div className="search-engine-selection-google">
        
        <input
          disabled={loader}
          value={"google"}
          checked={selectedEngines.includes("google")}
          onChange={(event) => {
            handleEngineSelection(event.target.checked, "google");
          }}
          type="checkbox"
          className="search-engine-selection-checkbox2"
        />
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="search-engine-selection-image"
        />
        <span className="search-engine-selection-text">{props.Google}</span>
      </div>
      {/* <div className="search-engine-selection-yahoo">
        <input
          disabled={loader}
          value={"yahoo"}
          checked={selectedEngines.includes("yahoo")}
          onChange={(event) => {
            handleEngineSelection(event.target.checked, "yahoo");
          }}
          type="checkbox"
          className="search-engine-selection-checkbox3"
        />
        <img
          alt={props.image_alt21}
          src={props.image_src21}
          className="search-engine-selection-image3"
        />
        <span className="search-engine-selection-text3">{props.Yahoo}</span>
      </div> */}
      {/* <div className="search-engine-selection-baidu">
        <input
          disabled={loader}
          value={"baidu"}
          checked={selectedEngines.includes("baidu")}
          onChange={(event) => {
            handleEngineSelection(event.target.checked, "baidu");
          }}
          type="checkbox"
          className="search-engine-selection-checkbox4"
        />
        <img
          alt={props.image_alt1}
          src={props.image_src1}
          className="search-engine-selection-image4"
        />
        <span className="search-engine-selection-text4">{props.Baidu}</span>
      </div> */}
      {/* <div className="search-engine-selection-startpages">
        <input
          disabled={loader}
          value={"startPage"}
          checked={selectedEngines.includes("startPage")}
          onChange={(event) => {
            handleEngineSelection(event.target.checked, "startPage");
          }}
          type="checkbox"
          className="search-engine-selection-checkbox5"
        />
        <img
          alt={props.image_alt11}
          src={props.image_src11}
          className="search-engine-selection-image5"
        />
        <span className="search-engine-selection-text5">
          {props.Startpages}
        </span>
      </div> */}
    </div>
  );
};

SearchEngineSelection.defaultProps = {
  image_src21: "/playground_assets/layer1.svg",
  image_src11: "/playground_assets/group1.svg",
  Startpages: "Startpages",
  Google: "Google",
  Baidu: "Baidu",
  image_alt2: "image",
  image_alt21: "image",
  image_src2: "/playground_assets/vector.svg",
  image_alt1: "image",
  image_alt: "image",
  image_alt11: "image",
  Bing: "Bing",
  DuckDuckGo: "DuckDuckGo",
  rootClassName: "",
  image_src3: "/playground_assets/duckduckgo-seeklogo.com%201.svg",
  image_src: "/playground_assets/google__g__logo%201.svg",
  image_src1: "/playground_assets/g2225.svg",
  Yahoo: "Yahoo",
  image_alt3: "image",
};

SearchEngineSelection.propTypes = {
  image_src21: PropTypes.string,
  image_src11: PropTypes.string,
  Startpages: PropTypes.string,
  Google: PropTypes.string,
  Baidu: PropTypes.string,
  image_alt2: PropTypes.string,
  image_alt21: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt1: PropTypes.string,
  image_alt: PropTypes.string,
  image_alt11: PropTypes.string,
  Bing: PropTypes.string,
  DuckDuckGo: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src3: PropTypes.string,
  image_src: PropTypes.string,
  image_src1: PropTypes.string,
  Yahoo: PropTypes.string,
  image_alt3: PropTypes.string,
};

export default SearchEngineSelection;
