import React from "react";
import "./loader.css";

const LoaderPowerSearch = (props) => {
  return (
    <div className={`${props.className ? props.className : "loader-container"}`}>
      <div className="loader" />
      <div className="loader-text">Analyzing criticalities...</div>
    </div>
  );
};

export default LoaderPowerSearch;
