import React from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import ProfileSide from "../components/profile-side";
import "./power-search.css";
import ArchivioComponent from "../components/archivio-component";
import { useHistory } from "react-router-dom";
import Hamburgher from "../components/hamburgher-menu";

const Archivio = (props) => {
  const history = useHistory();
 






  return (
    <div className="power-search-container">
      <Helmet>
        <title>Archivio - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
        </div>
     
      
        <div className="menudesktop">
        <div className="nomenurightprofile">
    <ProfileSide  />

    </div>
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 

    </div>
      </div>

      <div className="power-search-results-divarchivio">
        <ArchivioComponent
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("archivio-dettaglio");
          }}
        />
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default Archivio;
