import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTextArea,
  MDBIcon,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Typed from "react-typed";
import { flags } from "./flags";
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/build/pdf.worker.entry';
import '@fortawesome/fontawesome-free/css/all.min.css';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import "./chatComponent.css";
import "./chatHeadToolbar.css";
import SendIcon from '../images/send.png';
import ImmagineToolbar1 from '../images/IconaAiTools1.png'
import ImmagineToolbar2 from '../images/IconaAiTools2.png'
import ImmagineToolbar3 from '../images/IconaAiTools3.png'
import ImmagineToolbar4 from '../images/IconaAiTools4.png'
import ImmagineToolbar5 from '../images/IconaAiTools5.png'
import ImmagineToolbar6 from '../images/IconaAiTools6.png'
import ImmagineToolbar7 from '../images/IconaAiTools7.png'
import ImmagineToolbar8 from '../images/IconaAiTools8.png'
import weaglecoinicon from '../images/weagleCoiniconTransparent.png'
import ImmagineToolbar9 from '../images/IconaAiTools9.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as XLSX from 'xlsx';
 const BaseURL = "https://chatweagle-7ce84ac5961e.herokuapp.com";
//const BaseURL = "http://localhost:5000";


function isFirefox() {
  return navigator.userAgent.includes('Firefox');
}

if (!isFirefox()) {
  var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;

  var recognition = new SpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.maxAlternatives = 5;
}


const fetchAudioFile = async (audioId) => {
  try {
    const playhtInstance = axios.create({
      baseURL: "https://play.ht/api/v1/articleStatus",
      headers: {
        "Content-Type": "application/json",
        "X-User-ID": "xtjZNO2RaqWaW7FD8b3Z5Pu65gy2", // Replace with your User ID
        Authorization: "d96f573eef5945bd9fbcfef4d14e38af", // Replace with your API key
      },
    });

    return await playhtInstance.get(`?transcriptionId=${audioId}`);
  } catch (error) {
    console.error("Error fetching audio file:", error);
  }
};

const fetchAudioUntilConverted = async (audioId) => {
  return new Promise((resolve, reject) => {
    const checkAudioStatus = async () => {
      try {
        const audioResponse = await fetchAudioFile(audioId);
        if (audioResponse?.data?.converted) {
          clearInterval(interval);
          resolve(audioResponse);
        }
      } catch (error) {
        clearInterval(interval);
        reject(error);
      }
    };

    const interval = setInterval(checkAudioStatus, 1500);
  });
};

const Sent = ({ message }) => {
  return (
    <div className="d-flex justify-content-end mb-4">
      <div
        className="p-3 me-3 border"
        style={{ width:'auto', maxWidth:'100%', borderRadius: "15px", backgroundColor: "rgba(237,237,237,1)", color:'black'}}
      >
        <p className="small mb-0">{message}</p>
      </div>
      {/* <img
        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
        alt="avatar 1"
        style={{ width: "45px", height: "100%" }}
      /> */}
    </div>
  );
};

const Received = ({
  message,
  index,
  allMessages,
  handleMessageDetail,
  isDetail,
}) => {
  const handleDetailsClick = (currentIndex, messagesArray) => {
    const currentMessage = messagesArray[currentIndex];
    const previousMessage =
      currentIndex > 0 ? messagesArray[currentIndex - 1] : null;

    const message = `From \n\n${previousMessage?.text} to \n\n${currentMessage?.text}\n  Dettaglia`;
    handleMessageDetail(message);
  };

  function formatMessage(msg) {
    // Function to sanitize input to prevent injection attacks
    function sanitize(text) {
      return text.replace(/&/g, '&amp;')
                 .replace(/</g, '&lt;')
                 .replace(/>/g, '&gt;')
                 .replace(/"/g, '&quot;')
                 .replace(/'/g, '&#039;');
    }
  
    // Function to detect and format code
    function formatCode(text) {
      const codeRegex = /(```[^\n]*\n[\s\S]*?\n```)|(`[\s\S]*?`)/g;
  
      return text.replace(codeRegex, match => {
        let code = match.startsWith('```') ? match.slice(3, -3) : match.slice(1, -1);
        code = sanitize(code)
          // Additional syntax highlighting and formatting can be added here
          .replace(/(\/\/.*)/g, '<span style="color: #75715E;">$1</span>'); // Comments
  
        const style = `background: #272822; color: #F8F8F2; padding: 10px; border-radius: 4px; font-family: 'Courier New', Courier, monospace; overflow-x: auto; white-space: pre;`;
        return match.startsWith('```') ? `<pre style="${style}">${code}</pre>` : `<code style="${style}">${code}</code>`;
      });
    }
  
    function formatList(text) {
      // This regex matches ordered list items (1., 2., etc.) and captures the entire list block
      const listBlockRegex = /(\n|^)(\d+\.\s.+?)(?=(\n\d+\.\s|\n\n|\n$|$))/gs;
  
      return text.replace(listBlockRegex, (match, p1, p2) => {
          // Add an extra line break between list items, and ensure the first item doesn't start with a line break
          const itemsWithExtraLineBreak = p2.split(/\n(?=\d+\.\s)/).join('\n\n');
          return p1 + (p1 ? '\n' : '') + itemsWithExtraLineBreak;
      });
    }
  
    // Function to apply simple markdown-like formatting for bold and italic
    function applyTextStyles(text) {
      return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold: **text**
        .replace(/__(.*?)__/g, '<strong>$1</strong>')   // Bold with underscores
        .replace(/\*(.*?)\*/g, '<em>$1</em>')           // Italic: *text*
        .replace(/_(.*?)_/g, '<em>$1</em>');            // Italic with underscores
    }
  
    // Function to replace new lines with <br /> tags, and insert line breaks after every second period.
    function preserveLineBreaks(text) {
      let newText = text.replace(/(\.[^\.]*\.)/g, '$1\n'); // Insert a newline after every second period.
      return newText.replace(/\n/g, '<br />');
    }
  
    // Trim and format the message
    let formatted = msg.trim();
    formatted = formatCode(formatted);
    formatted = formatList(formatted);
    formatted = applyTextStyles(formatted);
    formatted = preserveLineBreaks(formatted);
  
    return formatted;
  }
  // Format the message
  let formattedMessage = formatMessage(message);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
  };
  
  return (
    <div  className="d-flex flex-row justify-content-start mb-4">
      {/* <img
        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
        alt="avatar 1"
        style={{ width: "45px", height: "100%" }}
      /> */}
      <div
        className="p-3 ms-3 position-relative"
        style={{
          borderRadius: "15px",
          backgroundColor: "rgba(255,255,255,0.5)",
          boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
          filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))",
        }}
      >
        <p className="small mb-0">
     
        <p dangerouslySetInnerHTML={{ __html: formattedMessage }}></p>
  
        </p>
       

        {!isDetail && (
          <div>
            <div style={{height: "1px",  boxShadow: "0 1px 1px rgba(0, 0, 0, 0.2)", width: "100%", marginTop:'20px', marginBottom:'20px'}} className="lineadivchat"></div>
            <CopyToClipboard text={message} onCopy={handleCopy}>
          <button style={{ borderRadius: "15px",
          color:'#ff9900',
          backgroundColor: "#fff",
          padding:'7px',
          marginTop:'10px',
          width:'130px',
          fontFamily:"Encode Sans Expanded",
          fontWeight:'bold',
          fontSize:'12px',
          textAlign:'center',
          cursor:'pointer',
          borderWidth:'1px',
          borderColor:'#ff9900',
          borderStyle:'solid',
        }}>
            {isCopied ? 'Copiato!' : 'Copia'}
          </button>
        </CopyToClipboard>
          </div>
    )}
      </div>
            
            

    </div>
  );
};
const translations = {
  en: {
   
    'ppps1segnala': "Ask me something...",
    'ppps1segnala2': "Welcome to the Weagle chat, how can I assist you? For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
    'ppps1segnala3': "As a financial analyst, I can provide advice and support in various financial areas, including financial data analysis, investment valuation, risk management, and more. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
    'ppps1segnala4': "As a marketing consultant, I can assist you with market analysis, target definition, development of a marketing strategy, SEO and digital marketing, and performance analysis. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
    'ppps1segnala5': "I can help you write various types of business emails such as contact emails with suppliers, introductory emails, follow-up emails, thank you emails, or order confirmation emails. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
    'ppps1segnala6': "I can create templates for corporate documents such as confidentiality letters, service contracts, business proposals, monthly reports, internal memos, and much more. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
    'ppps1segnala7': "I can search for specific content online and provide you with a summary of the topic.",
    'ppps1segnala8': "For maximum corporate privacy, remember not to use proper names or sensitive personal information.",
    'ppps1segnala9': "Chat with Weagle thanks to artificial intelligence.",
    'ppps1segnala10': "What can I ask my assistant:",
    'ppps1segnala11': '"We are opening a division that will deal with X, what are the growth rates of the sector and the main issues?"',
    'ppps1segnala12': '"Write me a draft of a contract for a collaboration on a new project..."',
    'ppps1segnala13': '"Can you create a comprehensive report on company X spa that we are acquiring?"',
    'ppps1segnala14': "Choose the tone of the conversation you want to start:",
    'ppps1segnala15': "CONCISE",
    'ppps1segnala16': "TECHNICAL",
    'ppps1segnala17': "DETAILED",
    'ppps1segnala18': "Leverage the power of ChatGPT with all the safety and privacy of Weagle Enterprise.",
    'ppps1segnala19': "Terms and conditions",
    'ppps1segnala20': "START",
  },
  it: {


    'ppps1segnala': 'Chiedimi qualcosa...',
    'ppps1segnala2': 'Benvenuto nella chat di weagle, come posso aiutarti? Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema',
    'ppps1segnala3': "Come analista finanziario, posso fornire consulenza e supporto in diverse aree finanziarie, compresa l'analisi dei dati finanziari, la valutazione degli investimenti, la gestione del rischio e altro ancora. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
    'ppps1segnala4': "Come consulente marketing posso aiutarti con: analisi del mercato, definizione del target, sviluppo di una strategia di marketing, SEO e marketing digitale e Analisi delle performance. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
    'ppps1segnala5': "Posso aiutarti a scrivere diversi tipi di email aziendali come ad esempio: mail di contatto con fornitori, di presentazione, di follow up, di ringraziamento oppure di conferma d'ordine. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
    'ppps1segnala6': "Posso realizzare template di documenti aziendali come ad esempio: lettere di riservatezza, contratti di servizio, proposte commerciali, report mensili, comunicati interni e tanto altro. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
    'ppps1segnala7': "Posso cercare del contenuto specifico online e fornirti una sintesi dell'argomento",
    'ppps1segnala8': "Per la massima privacy aziendale ricorda di non utilizzare nomi propri o informazioni personali sensibili.",
    'ppps1segnala9': "Chatta con Weagle grazie all’intelligenza artificiale",
    'ppps1segnala10': "Cosa posso chiedere al mio assistente:",
    'ppps1segnala11': '“Stiamo aprendo una divisione che si occuperà di X, quali sono i tassi di crescita del settore e le principali criticità”',
    'ppps1segnala12': '”Scrivimi una bozza di un contratto per una collaborazione per un nuovo progetto... ”',
    'ppps1segnala13': '”Puoi crearmi un report completo sulla azienda X spa che stiamo acquisendo”',
    'ppps1segnala14': "Scegli il tono della conversazione che vuoi avviare:",
    'ppps1segnala15': "CONCISO",
    'ppps1segnala16': "TECNICO",
    'ppps1segnala17': "DETTAGLIATO",
    'ppps1segnala18': "Sfrutta la potenza di ChatGPT con tutta la sicurezza e privacy di Weagle Enterprise",
    'ppps1segnala19': "Condizioni di utilizzo",
    'ppps1segnala20': "INIZIA",
  },
};
function translate(key, lang) {
  const italianTranslations = translations.it;

  if (lang === 'it' && italianTranslations[key]) {
    return italianTranslations[key];
  }

  return translations.en[key];
}
const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

const ppps1segnal = translate('ppps1segnala', lang);
const ppps1segnal2 = translate('ppps1segnala2', lang);
const ppps1segnal3 = translate('ppps1segnala3', lang);
const ppps1segnal4 = translate('ppps1segnala4', lang);
const ppps1segnal5 = translate('ppps1segnala5', lang);
const ppps1segnal6 = translate('ppps1segnala6', lang);
const ppps1segnal7 = translate('ppps1segnala7', lang);
const ppps1segnal8 = translate('ppps1segnala8', lang);
const ppps1segnal9 = translate('ppps1segnala9', lang);
const ppps1segnal10 = translate('ppps1segnala10', lang);
const ppps1segnal11 = translate('ppps1segnala11', lang);
const ppps1segnal12 = translate('ppps1segnala12', lang);
const ppps1segnal13 = translate('ppps1segnala13', lang);
const ppps1segnal14 = translate('ppps1segnala14', lang);
const ppps1segnal15 = translate('ppps1segnala15', lang);
const ppps1segnal16 = translate('ppps1segnala16', lang);
const ppps1segnal17 = translate('ppps1segnala17', lang);
const ppps1segnal18 = translate('ppps1segnala18', lang);
const ppps1segnal19 = translate('ppps1segnala19', lang);
const ppps1segnal20 = translate('ppps1segnala20', lang);

const ModeSelection = ({ onSelect }) => {
  // Add state for selected mode
  const [selectedMode, setSelectedMode] = useState("basic");
  
  return (
    <div style={{marginTop:'70px'}}>
      {/* <h1 style={{ fontFamily: "Encode Sans Expanded", fontSize: '17px', textAlign: 'center', marginBottom: '20px' }}>
      {ppps1segnal14}
      </h1> */}
      
      <div className="d-flex flex-sm-row flex-column justify-content-center mb-4">
        {/* <button
          onClick={() => setSelectedMode("basic")}
          style={{
            minWidth:'135px',
            backgroundColor: selectedMode === "basic" ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.3)",
            color: selectedMode === "basic" ? "#000080" : "#000080",
            border:'solid 1px #000080',
            boxShadow:'none'
          }}
          className="btn  me-2"
        >
          {ppps1segnal15}
        </button> */}
        
        {/* <button
          onClick={() => setSelectedMode("middle")}
          style={{
            minWidth:'135px',
            backgroundColor: selectedMode === "middle" ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.3)",
            color: selectedMode === "basic" ? "#000080" : "#000080",
            border:'solid 1px #000080',
            boxShadow:'none'
          }}
          className="btn  me-2"
        >
          {ppps1segnal16}
        </button>
        
        <button
          onClick={() => setSelectedMode("advanced")}
          style={{
            minWidth:'135px',
            backgroundColor: selectedMode === "advanced" ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.3)",
            color: selectedMode === "basic" ? "#000080" : "#000080",
            border:'solid 1px #000080',
            boxShadow:'none'
          }}
          className="btn me-2 "
        >
          {ppps1segnal17}
        </button> */}
      </div>
      
      <h1 style={{ lineHeight:'22px',fontFamily: "Encode Sans Expanded", fontSize: '15px', textAlign: 'center', marginTop: '20px', marginBottom:'60px' }}>
      {ppps1segnal18}


        </h1>
   
      <div className='d-flex flex-sm-row flex-column justify-content-center mb-4'>
      <button
        onClick={() => onSelect(selectedMode)}
        style={{     boxShadow:'none',fontWeight:'bold',fontFamily: "Encode Sans Expanded",fontSize: '17px',minWidth:'205px',height:'50px',backgroundColor: "#063565", color:'#ffff', border:'none'  }}
        className="btn  me-2"
      >
      {ppps1segnal20}
      </button>
      
      </div>
      <div className='d-flex flex-sm-row justify-content-center mb-4'>
      <h1 style={{curor:'pointer',color:"#000080",  lineHeight:'22px',fontFamily: "Encode Sans Expanded", fontSize: '15px', textAlign: 'center', marginTop: '20px', marginBottom:'60px' }}>
      <a href='https://www.weagle.it/privacy.html' target="_blank"> Privacy</a>
     &nbsp;

        </h1>
        <h1 style={{color:"#000080", lineHeight:'22px',fontFamily: "Encode Sans Expanded", fontSize: '15px', textAlign: 'center', marginTop: '20px', marginBottom:'60px' }}>
      | &nbsp;


        </h1>
        <h1 style={{curor:'pointer',color:"#000080", lineHeight:'22px',fontFamily: "Encode Sans Expanded", fontSize: '15px', textAlign: 'center', marginTop: '20px', marginBottom:'60px' }}>
        <a href='https://www.weagle.it/terms.html' target="_blank"> {ppps1segnal19}</a>


        </h1>
      
      </div>
    </div>
  );
};



const Loader = () => {
  return (
    <div className="d-flex flex-row justify-content-start mb-4">
      <img
        src="https://mir-s3-cdn-cf.behance.net/project_modules/max_632/04de2e31234507.564a1d23645bf.gif"
        alt="avatar 1"
        style={{ width: "55px", height: "100%" }}
      />
      <div
        className="p-3 mt-2"
        style={{
          borderRadius: "15px",
        }}
      >
        <div className="typing-loader"></div>
      </div>
    </div>
  );
};

export default function ChatComponent() {
  const [messages, setMessages] = React.useState([]);
  const [input, setInput] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingReset, setLoadingReset] = React.useState(false);
  const [listening, setListening] = React.useState(false);
  const [recognitionError, setRecogError] = React.useState("");
  const audioRef = useRef(new Audio("http://google.com"));
  const [mode, setMode] = React.useState(null);
  const [firstInput, setFirstInput] = React.useState(true);
  const [selectedLang, setSelectedLang] = React.useState("en-US");
  const [voice, setVoice] = React.useState("en-US-AriaNeural");
  const [generateVoice, setGenerateVoice] = useState(false); // Added state for voice generation toggle
// When getting messages from state
const [chatInProgress, setChatInProgress] = useState(false);
const [uploadModal, setUploadModal] = useState(false);
const [selectedFile, setSelectedFile] = useState(null);
const [anonymizeToggle, setAnonymizeToggle] = useState(false);
const [extractedText, setExtractedText] = useState("");






const handleFileSelect = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  // Function to process the extracted text
  const processExtractedText = (text, anonymize) => {
    if (anonymize) {
      // Complex regular expressions for various sensitive data
      const nameRegex = /\b(?:[A-Z][a-z]+(?:\s|-)){1,2}[A-Z][a-z]+\b/g;
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
      const phoneRegex = /(\+\d{1,3}[- ]?)?(\(?\d{3}\)?[- ]?)?\d{3}[- ]?\d{4}/g;
      const addressRegex = /\b\d{1,6}\s[A-Za-z0-9\s,.]{5,99}(Street|St\.?|Avenue|Ave\.?|Road|Rd\.?|Boulevard|Blvd\.?|Drive|Dr\.?|Court|Ct\.?|Lane|Ln\.?|Way)\b/g;
      const companyRegex = /\b[A-Z][A-Za-z0-9&]*(?:\s[A-Z][A-Za-z0-9&]*){1,3}\b/g;

      // Function to anonymize sensitive data
      const anonymizeData = (regex, placeholder) => {
        text = text.replace(regex, placeholder);
      };

      // Anonymizing different types of data
      anonymizeData(nameRegex, '[Name]');
      anonymizeData(emailRegex, '[Email]');
      anonymizeData(phoneRegex, '[Phone]');
      anonymizeData(addressRegex, '[Address]');
      anonymizeData(companyRegex, '[Company]');
    }

    return text;
  };

  const wordCount = (text) => text.split(/\s+/).length;

  // Get the user ID from localStorage
  const userId = localStorage.getItem("currentId");

  let fileAllowed = false;
  let confidentialLevel = "public"; // Default confidential level

  // Function to extract text from PDF
  const extractTextFromPDF = async (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    return new Promise((resolve) => {
      fileReader.onload = async () => {
        const typedArray = new Uint8Array(fileReader.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        let extractedText = '';

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(' ');
          extractedText += pageText + '\n';
        }

        resolve(extractedText);
      };
    });
  };

  // Function to extract text from DOCX
  const extractTextFromDocx = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const content = e.target.result;
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });
        doc.render();
        const text = doc.getFullText();
        resolve(text);
      };
      fileReader.onerror = (e) => {
        reject(e);
      };
      fileReader.readAsBinaryString(file);
    });
  };

  // Function to extract text from XLS
  const extractTextFromXls = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        let extractedText = '';

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetText = XLSX.utils.sheet_to_csv(worksheet);
          extractedText += sheetText + '\n';
        });

        resolve(extractedText);
      };
      fileReader.onerror = (e) => {
        reject(e);
      };
      fileReader.readAsBinaryString(file);
    });
  };

  if (file.type === "application/pdf") {
    try {
      // Extract text from PDF
      const extractedText = await extractTextFromPDF(file);

      // Check for confidentiality level in the header
      const headerText = extractedText.split('\n').slice(0, 5).join(' ').toLowerCase();
      if (headerText.includes('public')) {
        confidentialLevel = 'public';
      } else if (headerText.includes('confidential')) {
        confidentialLevel = 'confidential';
      } else if (headerText.includes('internal')) {
        confidentialLevel = 'internal';
      } else if (headerText.includes('restricted')) {
        confidentialLevel = 'restricted';
      } else if (headerText.includes('private')) {
        confidentialLevel = 'private';
      }

      const response = await fetch('https://as-weagle-test-weu-001.azurewebsites.net/checkprivacyai', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, fileType: 'pdf', confidentialLevel })
      });
      const data = await response.json();
      console.log('PDF privacy response:', data);

      if (data.status) {
        fileAllowed = data.message.pdf && data.message[confidentialLevel];
      }

      if (fileAllowed) {
        const processedText = processExtractedText(extractedText, anonymizeToggle);
        const sensitiveDataCount = (extractedText.match(/(Name|Email|Phone|Address|Company)/g) || []).length;
        if (sensitiveDataCount >= 10) {
          confidentialLevel = "high";
        } else if (sensitiveDataCount >= 5) {
          confidentialLevel = "medium";
        }
        console.log("Extracted text from PDF:", processedText);
        setExtractedText(processedText);
      }
    } catch (error) {
      console.error('Error checking PDF privacy:', error);
    }
  } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    try {
      // Extract text from DOCX
      const extractedText = await extractTextFromDocx(file);

      // Check for confidentiality level in the header
      const headerText = extractedText.split('\n').slice(0, 5).join(' ').toLowerCase();
      if (headerText.includes('public')) {
        confidentialLevel = 'public';
      } else if (headerText.includes('confidential')) {
        confidentialLevel = 'confidential';
      } else if (headerText.includes('internal')) {
        confidentialLevel = 'internal';
      } else if (headerText.includes('restricted')) {
        confidentialLevel = 'restricted';
      } else if (headerText.includes('private')) {
        confidentialLevel = 'private';
      }

      const response = await fetch('https://as-weagle-test-weu-001.azurewebsites.net/checkprivacyai', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, fileType: 'docx', confidentialLevel })
      });
      const data = await response.json();
      console.log('DOCX privacy response:', data);

      if (data.status) {
        fileAllowed = data.message.docx && data.message[confidentialLevel];
      }

      if (fileAllowed) {
        const processedText = processExtractedText(extractedText, anonymizeToggle);
        const sensitiveDataCount = (extractedText.match(/(Name|Email|Phone|Address|Company)/g) || []).length;
        if (sensitiveDataCount >= 10) {
          confidentialLevel = "high";
        } else if (sensitiveDataCount >= 5) {
          confidentialLevel = "medium";
        }
        console.log("Extracted text from DOCX:", processedText);
        setExtractedText(processedText);
      }
    } catch (error) {
      console.error('Error checking DOCX privacy:', error);
    }
  } else if (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    try {
      // Extract text from XLS
      const extractedText = await extractTextFromXls(file);

      // Check for confidentiality level in the header
      const headerText = extractedText.split('\n').slice(0, 5).join(' ').toLowerCase();
      if (headerText.includes('public')) {
        confidentialLevel = 'public';
      } else if (headerText.includes('confidential')) {
        confidentialLevel = 'confidential';
      } else if (headerText.includes('internal')) {
        confidentialLevel = 'internal';
      } else if (headerText.includes('restricted')) {
        confidentialLevel = 'restricted';
      } else if (headerText.includes('private')) {
        confidentialLevel = 'private';
      }
  
   
      const response = await fetch('https://as-weagle-test-weu-001.azurewebsites.net/checkprivacyai', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, fileType: 'xlsx', confidentialLevel })
      });
      const data = await response.json();
      console.log('XLS privacy response:', data);
      console.log('File type allowed:', data.message.xlsx);
      console.log('Confidentiality level allowed:', data.message[confidentialLevel]);
  
      if (data.status) {
        fileAllowed = data.message.xlsx && data.message[confidentialLevel];
        console.log('File allowed:', fileAllowed);
      }

      if (fileAllowed) {
        const processedText = processExtractedText(extractedText, anonymizeToggle);
        const sensitiveDataCount = (extractedText.match(/(Name|Email|Phone|Address|Company)/g) || []).length;
        if (sensitiveDataCount >= 10) {
          confidentialLevel = "high";
        } else if (sensitiveDataCount >= 5) {
          confidentialLevel = "medium";
        }
        console.log("Extracted text from XLS:", processedText);
        setExtractedText(processedText);
      }
    } catch (error) {
      console.error('Error checking XLS privacy:', error);
    }
  } else {
    toast.error("Please select a valid file (.pdf, .docx, .xls, .xlsx)", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    event.target.value = ''; // Reset the file input
    return;
  }

  if (!fileAllowed) {
    toast.error(`Error: ${file.type} file with confidentiality level "${confidentialLevel}" is not allowed`, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    event.target.value = ''; // Reset the file input
    return;
  }

  console.log("Document Confidential Level:", confidentialLevel);
  setSelectedFile(file);
};



const toggleUploadModal = () => setUploadModal(!uploadModal);
// Method to start a new chat message
const startChatMessage = () => {
  setChatInProgress(true);
  
  // Replace this with your actual method to start a chat message
  // Don't forget to call `endChatMessage` once the chat message is finished
};

// Method to end a chat message
const endChatMessage = () => {
  setChatInProgress(false);
};


useEffect(() => {
    // Load messages from localStorage when component mounts
    const localMessages = localStorage.getItem('chatMessages');
    if (localMessages) {
        setMessages(JSON.parse(localMessages));
    }
}, []);

useEffect(() => {
    // Save messages to localStorage whenever they change
    localStorage.setItem('chatMessages', JSON.stringify(messages));
}, [messages]);


useEffect(() => {
    const localInput = localStorage.getItem('chatInput');
    if (localInput) {
        setInput(localInput);
    }
}, []);

useEffect(() => {
    localStorage.setItem('chatInput', input);
}, [input]);

// For the mode


useEffect(() => {
    const localMode = localStorage.getItem('chatMode');
    if (localMode) {
        setMode(localMode);
    }
}, []);

  // Function to handle language selection
  const handleLanguageSelection = (item) => {
    setSelectedLang(item?.lang);
    setVoice(item?.voice);
  };

  // Automatically set the language based on the user's browser on component mount
  useEffect(() => {
    const browserLang = navigator.language || navigator.userLanguage;
    // You might want to map browserLang to your supported languages here
    setSelectedLang(browserLang);
    // Set up any other initial settings for recognition here
  }, []);

  // Effect to handle changes in selectedLang
  useEffect(() => {
    if (!isFirefox()) { // Replace isFirefox with your actual check
      if (listening) {
        recognition.stop(); // Stop the recognition process
      }
      recognition.lang = selectedLang; // Update the language
      // recognition.start(); // Restart the recognition process
    }
  }, [selectedLang, listening]);

if (!isFirefox()) {
  useEffect(() => {
    if (recognition) {
      recognition.onsoundstart = () => {
        setListening(true);
      };
      recognition.onend = () => {
        setListening(false);
      };
      recognition.onerror = () => {
        setRecogError("Can't hear anything, try again");
      };
      recognition.nomatch = () => {
        setRecogError("Can't hear anything, try again");
      };
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        if (transcript?.trim().length > 0) {
          setInput(transcript.trim());
        }
      };
    }
  }, [JSON.stringify(recognition)]);
}




  const flagDropdown = () => {
    return (
      <MDBDropdown>
        <MDBDropdownToggle tag="a" className="nav-link">
          {flags.find((item) => item.lang === selectedLang)?.flag}
        </MDBDropdownToggle>
        <MDBDropdownMenu style={{ minWidth: "auto" }}>
          {flags?.map((item, i) => {
            return (
              <MDBDropdownItem
                key={i}
                onClick={() => handleLanguageSelection(item)}
              >
                <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontSize: 20,
                    cursor: "pointer",
                    backgroundColor:
                      selectedLang === item?.lang
                        ? "rgb(166,234,255)"
                        : "white",
                  }}
                >
                  {item?.flag}
                </div>
              </MDBDropdownItem>
            );
          })}
        </MDBDropdownMenu>
      </MDBDropdown>
    );
  };

  const handleModeSelection = (selectedMode) => {
    if (selectedMode) {
      setMode(selectedMode);
      setFirstInput(false);
      getCorrectSentence(input, selectedMode);
    }
  };

  const getUid = () => {
    let uid = localStorage.getItem("uid");
    if (!uid) {
      uid = JSON.stringify(Math.random());
      localStorage.setItem("uid", uid);
    }
    return uid;
  };

  const formatMessage = (msg) => {
    let message = msg.replaceAll("AI Couch: ", "");
    message = message.replaceAll("Corrected text: ", "");
    message = message.replaceAll("Correction Summary: ", "<br /><br />");
    message = message.replaceAll("Advice: ", "<br />");
    message = message.replaceAll("Answer: ", "<br /><br />");

    return message;
  };
  const sessionId = Math.random().toString(36).substring(2, 15);
  const getMessageDetail = async (message) => {
    try {
      if (message?.trim() === "") return;
      setLoading(true);
      const response = await axios.post(BaseURL + "/messageDetail", {
        message,
        lang: selectedLang,
        voice,
        generateVoice,
        sessionId, 
      });

      if (response?.data?.audioId) {
        await fetchAudioUntilConverted(response?.data?.audioId)
          .then((audioFile) => {
            if (audioRef.current && audioFile.data?.audioUrl) {
              audioRef.current.pause();
              audioRef.current.src = audioFile.data?.audioUrl;
              audioRef.current.play();
            }
          })
          .catch((error) => {
            console.error("Error fetching audio:", error);
          });
      }

      setMessages((prev) => [
        ...prev,
        {
          type: "receive",
          text: formatMessage(response?.data?.message),
          detail: true,
        },
      ]);
    } catch (e) {
    
      toast.error("Tokens number exceeded, try a shorter message", { 
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  
  const [Aimode, AisetMode] = useState('ChatBase');
  const translations = {
    en: {
     
      'ppps1segnala': "Ask me something...",
      'ppps1segnala2': "Welcome to the Weagle chat, how can I assist you? For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
      'ppps1segnala3': "As a financial analyst, I can provide advice and support in various financial areas, including financial data analysis, investment valuation, risk management, and more. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
      'ppps1segnala4': "As a marketing consultant, I can assist you with market analysis, target definition, development of a marketing strategy, SEO and digital marketing, and performance analysis. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
      'ppps1segnala5': "I can help you write various types of business emails such as contact emails with suppliers, introductory emails, follow-up emails, thank you emails, or order confirmation emails. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
      'ppps1segnala6': "I can create templates for corporate documents such as confidentiality letters, service contracts, business proposals, monthly reports, internal memos, and much more. For an optimal experience and to receive relevant answers, we suggest uploading only documents related to the topic.",
      'ppps1segnala7': "I can search for specific content online and provide you with a summary of the topic.",
      'ppps1segnala8': "For maximum corporate privacy, remember not to use proper names or sensitive personal information.",
      'ppps1segnala9': "Chat with Weagle thanks to artificial intelligence.",
      'ppps1segnala10': "What can I ask my assistant:",
      'ppps1segnala11': '"We are opening a division that will deal with X, what are the growth rates of the sector and the main issues?"',
      'ppps1segnala12': '"Write me a draft of a contract for a collaboration on a new project..."',
      'ppps1segnala13': '"Can you create a comprehensive report on company X spa that we are acquiring?"',
      'ppps1segnala14': "CLOSE",
      'ppps1segnala15': "CONCISE",
      'ppps1segnala16': "TECHNICAL",
      'ppps1segnala17': "DETAILED",
      'ppps1segnala18': "Leverage the power of <b>ChatGPT</b> with all the<br></br>safety and privacy of <b>Weagle Enterprise</b>.",
      'ppps1segnala19': "Chat with Weagle thanks to artificial intelligence.",
      'ppps1segnala20': "What can I ask my assistant:",
    },
    it: {
  
  
      'ppps1segnala': 'Chiedimi qualcosa...',
      'ppps1segnala2': 'Benvenuto nella chat di weagle, come posso aiutarti? Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema',
      'ppps1segnala3': "Come analista finanziario, posso fornire consulenza e supporto in diverse aree finanziarie, compresa l'analisi dei dati finanziari, la valutazione degli investimenti, la gestione del rischio e altro ancora. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
      'ppps1segnala4': "Come consulente marketing posso aiutarti con: analisi del mercato, definizione del target, sviluppo di una strategia di marketing, SEO e marketing digitale e Analisi delle performance. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
      'ppps1segnala5': "Posso aiutarti a scrivere diversi tipi di email aziendali come ad esempio: mail di contatto con fornitori, di presentazione, di follow up, di ringraziamento oppure di conferma d'ordine. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
      'ppps1segnala6': "Posso realizzare template di documenti aziendali come ad esempio: lettere di riservatezza, contratti di servizio, proposte commerciali, report mensili, comunicati interni e tanto altro. Per un esperienza ottimale e per avere risposte pertinenti ti suggeriamo di caricare solo documenti inerenti il tema",
      'ppps1segnala7': "Posso cercare del contenuto specifico online e fornirti una sintesi dell'argomento",
      'ppps1segnala8': "Per la massima privacy aziendale ricorda di non utilizzare nomi propri o informazioni personali sensibili.",
      'ppps1segnala9': "Chatta con Weagle grazie all’intelligenza artificiale",
      'ppps1segnala10': "Cosa posso chiedere al mio assistente:",
      'ppps1segnala11': '“Stiamo aprendo una divisione che si occuperà di X, quali sono i tassi di crescita del settore e le principali criticità”',
      'ppps1segnala12': '”Scrivimi una bozza di un contratto per una collaborazione per un nuovo progetto... ”',
      'ppps1segnala13': '”Puoi crearmi un report completo sulla azienda X spa che stiamo acquisendo”',
      'ppps1segnala14': "CHIUDI",
      'ppps1segnala15': "CONCISO",
      'ppps1segnala16': "TECNICO",
      'ppps1segnala17': "DETTAGLIATO",
      'ppps1segnala18': "Sfrutta la potenza di <b>ChatGPT</b> con tutta la<br></br> sicurezza e privacy di <b>Weagle Enterprise</b>",
      'ppps1segnala19': "Chatta con Weagle grazie all’intelligenza artificiale",
      'ppps1segnala20': "Cosa posso chiedere al mio assistente:",
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  
  const ppps1segnal = translate('ppps1segnala', lang);
  const ppps1segnal2 = translate('ppps1segnala2', lang);
  const ppps1segnal3 = translate('ppps1segnala3', lang);
  const ppps1segnal4 = translate('ppps1segnala4', lang);
  const ppps1segnal5 = translate('ppps1segnala5', lang);
  const ppps1segnal6 = translate('ppps1segnala6', lang);
  const ppps1segnal7 = translate('ppps1segnala7', lang);
  const ppps1segnal8 = translate('ppps1segnala8', lang);
  const ppps1segnal9 = translate('ppps1segnala9', lang);
  const ppps1segnal10 = translate('ppps1segnala10', lang);
  const ppps1segnal11 = translate('ppps1segnala11', lang);
  const ppps1segnal12 = translate('ppps1segnala12', lang);
  const ppps1segnal13 = translate('ppps1segnala13', lang);
  const ppps1segnal14 = translate('ppps1segnala14', lang);
  const ppps1segnal15 = translate('ppps1segnala15', lang);
  const ppps1segnal16 = translate('ppps1segnala16', lang);
  const ppps1segnal17 = translate('ppps1segnala17', lang);
  const ppps1segnal18 = translate('ppps1segnala18', lang);
  const ppps1segnal19 = translate('ppps1segnala19', lang);
  const ppps1segnal20 = translate('ppps1segnala20', lang);
  
  const [AimodeText, AisetModeText] = useState(ppps1segnal2);
  const changeAiMode = (newAiMode) => {
    AisetMode(newAiMode);
    setMessages([]);
    localStorage.removeItem('chatMessages'); // Clear the localStorage when resetting chat
  };
  const changeAiModeText = (newAiModeText) => {
    AisetModeText(newAiModeText);
  };


  const isChatBaseActive = Aimode === 'ChatBase';

  const chatBaseColor = isChatBaseActive ? '#000080' : 'initial';
  const chatBaseLine = isChatBaseActive ? 'solid 2px' : 'initial';




  const isAnalisiFinanziarieActive = Aimode === 'analisiFinanziarieMod';
  const analisiFinanziarieColor = isAnalisiFinanziarieActive ? '#000080' : 'initial';
  const analisiFinanziarieLine = isAnalisiFinanziarieActive ? 'solid 2px' : 'initial';

  const isMarketingActive = Aimode === 'marketingMod';
  const MarketingColor = isMarketingActive ? '#000080' : 'initial';
  const MarketingLine = isMarketingActive ? 'solid 2px' : 'initial';

  const isEmailActive = Aimode === 'emailMod';
  const emailColor = isEmailActive ? '#000080' : 'initial';
  const emailLine = isEmailActive ? 'solid 2px' : 'initial';

  const isDocumentiActive = Aimode === 'documentsMod';
  const documentsColor = isDocumentiActive ? '#000080' : 'initial';
  const documentsLine = isDocumentiActive ? 'solid 2px' : 'initial';

  const isWebActive = Aimode === 'webMod';
  const webColor = isWebActive ? '#000080' : 'initial';
  const webLine = isWebActive ? 'solid 2px' : 'initial';
  const handleChatSubmit = async (userInput) => {
    const fullMessage = `${userInput.trim()} ${extractedText}`.trim();
    if (fullMessage) {
      try {
        const enabledLabels = await getEnabledLabels();
        console.log("Enabled labels:", enabledLabels);
        const response = await validateInput(userInput, enabledLabels);
        console.log("Hugging Face API Response:", response);
  
        const labelScores = response.labels.map((label, index) => ({
          label,
          score: response.scores[index]
        }));
  
        const highestScore = Math.max(...response.scores);
        const highestLabel = response.labels[response.scores.indexOf(highestScore)];
  
        if (highestLabel !== "Other" && highestScore >= 0.4) {
          toast.error("High probability of sensitive data, check your prompts!", { 
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
          
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          
          // Reset the error message after a short delay (e.g., 3 seconds)
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        } else {
          setErrorMessage("");
        }
  
        await getCorrectSentence(userInput.trim());
        setInput("");
      } catch (error) {
        console.error("Error in handleChatSubmit:", error);
      }
    }
  };
  
  
  
  
  
  
 
  const getCorrectSentence = async (userTypedMessage) => {
    try {
        if (userTypedMessage?.trim() === "") return;
        
        // Combine user-typed message with extracted text and trim it
        const fullMessage = `${userTypedMessage.trim()} ${extractedText}`.trim();

        // Count the words in the full message
        const wordCount = fullMessage.split(/\s+/).filter(Boolean).length;

        console.log('Sending message:', fullMessage); // Log the full message being sent
        console.log('AI Mode:', Aimode); // Log the current AI Mode

        // Only add the user-typed message to the chat display
        setMessages([...messages, { type: "sent", text: userTypedMessage.trim() }]);
        setInput("");
        setLoading(true);

        // Log the full request payload
        console.log('Sending request with payload:', {
            message: fullMessage, // Send the full message including extracted text
            id: getUid(),
            mode: mode,
            aiMode: Aimode,
            lang: selectedLang,
            voice,
            generateVoice,
        });

        // Include the current AI mode in the request
        const response = await axios.post(BaseURL, {
            message: fullMessage, // Send the full message
            id: getUid(),
            mode: mode,
            aiMode: Aimode,
            lang: selectedLang,
            voice,
            generateVoice,
        });

        console.log('Received response:', response.data); // Log the received response

        if (response?.data?.audioId) {
            await fetchAudioUntilConverted(response?.data?.audioId)
                .then((audioFile) => {
                    console.log('Audio file received:', audioFile.data); // Log the audio file data
                    if (audioRef.current && audioFile.data?.audioUrl) {
                        audioRef.current.pause();
                        audioRef.current.src = audioFile.data?.audioUrl;
                        audioRef.current.play();
                    }
                })
                .catch((error) => {
                    console.error("Error fetching audio:", error);
                });
        }

        setMessages((prev) => [
            ...prev,
            {
                type: "receive",
                text: formatMessage(response?.data?.message),
                detail: false,
            },
        ]);

        // If the response is successful, send the word count to the /creditupdate endpoint
        if (response?.status === 200) {
          const userId = localStorage.getItem("currentId");
          if (userId) {
              await axios.post('https://as-weagle-test-weu-001.azurewebsites.net/deductGptCredits', {
                  userId: userId,
                  wordCount: wordCount
              });
          } else {
              console.error("User ID not found in localStorage");
          }
      }
      

    } catch (e) {
        console.error("Error during getCorrectSentence:", e); // Log any errors
        toast.error("Tokens number exceeded, try a shorter message", { 
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
        
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
    } finally {
        setLoading(false);
    }
};


  const resetChat = async () => {
    try {
      setLoadingReset(true);
      const response = await axios.post(`${BaseURL}/resetChat`, {
        id: getUid(),
      });

      if (response.data.status) {
        alert("Chat reset successful");
        setMessages([]);
      } else {
        alert("Failed to reset chat");
      }
    } catch (e) {
      alert("Failed to reset chat");
    } finally {
      setLoadingReset(false);
      setFirstInput(true);
      setMode(null);
    }
  };


  const onMicPress = () => {
    if (!isFirefox()) {
      // Code to enable or initialize speech recognition
 
    if (listening) {
      recognition.stop();
      setListening(false);
    } else {
      recognition.start();
      setListening(true);
      setRecogError("");
    }
  }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const isMobile = windowWidth < 1250;

  // Define your slides here. I've assumed that they're just divs with some text.
  const slides = [
    ppps1segnal11,
    ppps1segnal12,
    ppps1segnal13,
];


  // Slide every 3 seconds
  useEffect(() => {
      const timer = setInterval(() => {
          // Fade out
          setOpacity(0);

          setTimeout(() => {
              setCurrentIndex((currentIndex + 1) % slides.length);
              // Fade in
              setOpacity(1);
          }, 500);
      }, 3000);

      return () => clearInterval(timer);
  }, [currentIndex]);



  

  


  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const [processText, setProcessText] = useState(false);



 
  const [credits, setCredits] = useState(null);

  const userId = localStorage.getItem("currentId");
  useEffect(() => {
    const fetchCredits = async () => {
        try {
            
          const response = await axios.get(`https://as-weagle-test-weu-001.azurewebsites.net/getRemainingCredits/${userId}`);
          setCredits(response.data.data);
            if (response.data.success) {
                setCredits(response.data.data);
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    };

    fetchCredits();

    // Set interval to refresh credits
    const intervalId = setInterval(fetchCredits, 2000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
}, [userId]);


const onDrop = useCallback((event) => {
  event.preventDefault();
  event.stopPropagation();

  const files = event.dataTransfer.files;
  if (files && files.length > 0) {
    const file = files[0];
    setSelectedFile(file);
  }
}, []);

const onDragOver = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const clearFileData = () => {
  setExtractedText('');
  setSelectedFile(null);
};
const [showTooltip, setShowTooltip] = useState(false);
const [showTooltip2, setShowTooltip2] = useState(false);
const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
};
const textAreaRef = useRef(null);

useEffect(() => {
  // Automatically adjust the height of the textarea to fit the content
  const textArea = textAreaRef.current;
  if (textArea) {
    textArea.style.height = 'auto'; // Reset height to recalculate
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}, [input]); // Run whenever the input changes
const [errorMessage, setErrorMessage] = useState('');

const handleChange = (e) => {
  const value = e.target.value;
  setInput(value);
  localStorage.setItem('chatInput', value);
};

async function getEnabledLabels() {
  const userId = localStorage.getItem("currentId");

  const response = await fetch("https://as-weagle-test-weu-001.azurewebsites.net/checkdatamasking", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ userId })
  });

  if (response.ok) {
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      console.warn("Warning fetching enabled labels:", data.message);
      if (Array.isArray(data.message)) {
        return [...data.message, "Other"];
      } else {
        return ["Other"];
      }
    }
  } else {
    console.error("Error fetching enabled labels:", response.statusText);
    return ["Other"];
  }
}


async function validateInput(input, enabledLabels) {
  const response = await query({
    "inputs": input,
    "parameters": {
      "candidate_labels": enabledLabels.join(", ")
    }
  });

  return response;
}

async function query(data) {
  const response = await fetch(
    "https://tu6uqzg8cb3ueyt0.us-east-1.aws.endpoints.huggingface.cloud",
    {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  const result = await response.json();
  return result;
}
// Placeholder functions for NER and ML address detection
function isAddressNER(input) {
  // Use a NER library or service to detect addresses
  // Return true if an address is found, false otherwise
}

function isAddressML(input) {
  // Use a machine learning model trained for address detection
  // Return true if an address is detected, false otherwise
}



const handleKeyDown = (e) => {
  if (e.key === 'Enter' && !errorMessage) {
    e.preventDefault();
    // Send the input to the server or perform any desired action
    console.log('Input sent:', input);
    setInput('');
  }
};
function renderTextAreaOrErrorMessage() {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChatSubmit(input);
      setInput('');
    }
  };

  if (credits <= 0) {
      return <p style={{ fontFamily: "Encode Sans Expanded", color: 'red' }}>Error: No credits available.</p>;
  } else {
      return (
         <div>
            <label style={{marginTop:''}} class="form-label" for="form12">{ppps1segnal}</label>
            <div style={{ position: 'relative', width: '100%' }}>
            <textarea
        onChange={handleChange}
        value={input}
        className="form-control"
        id="textAreaExample"
        rows={1}
        style={{
          backgroundColor: 'E1E0E0',
          height: 'auto',
          maxHeight: '200px',
          padding: '10px 80px 10px 10px',
          overflowY: 'auto',
          resize: 'none',
        }}
        onKeyDown={handleKeyPress}
      />
 <button
        onClick={() => handleChatSubmit(input)}
        disabled={!!errorMessage}
        style={{
          position: 'absolute',
          right: '10px',
          bottom: '10px',
          zIndex: 1,
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 15,
          border: 'none',
          backgroundColor: errorMessage ? 'gray' : '#007bff', // Change color based on disabled state
          color: errorMessage ? 'white' : 'white', // Change text color based on disabled state
          cursor: errorMessage ? 'not-allowed' : 'pointer',
        }}
        className={`btn ${errorMessage ? 'btn-secondary' : 'btn-primary'}`} // Change button class based on disabled state
      >
<i>
      <img
        style={{
          width: '22px', // Make image take the full space of the parent
          height: '22px', // Make image take the full space of the parent
        }}
        src="https://beeluvd.eu/wp-content/uploads/2014/09/send-icon-white.png"
        alt="Send"
      />
    </i>
</button>


</div>
{errorMessage && <p className="error-message">{errorMessage}</p>}
{/* <div
                    onClick={onMicPress}
                    style={{
                       
                     
                       width:'45px',
                     height:'45px',
                        padding: 10,
                        backgroundColor: listening ? '#ff8080' : '#0E86D4',
                        borderRadius: 50,
                        cursor: 'pointer',
                        zIndex: 900,
                        textAlign:'right',
                      float:'right',
                      marginTop:'10px'
                    }}
                >
                    
                      <img
        src="https://assets.website-files.com/5b48d19f33b04e9bdf473dcb/5bbe1b3d527299b1d68a94ce_icons8-microphone-500-(1).gif"
        alt="mic"
        style={{ width: "100%", height: "100%" }}
      /> 
                    
                </div> */}
<div   onMouseEnter={() => setShowTooltip2(true)} 
            onMouseLeave={() => setShowTooltip2 (false)}
            
            style={{

              cursor: 'pointer', 
              gap: '0px', 
              justifyContent: 'left',
             
              flexDirection: 'row', 
              alignItems: 'center', 
              flex: 1,
           
              position: 'relative',
              width:'50px',
            }}
            >
{credits !== null ? (
    <p  style={{fontFamily:"Encode Sans Expanded",  display: 'flex', alignItems:'center', gap:'10px', marginTop:'10px'}}><img style={{width:'30px'}} src={weaglecoinicon}></img> {Number(credits).toFixed(2)}</p>
) : (
    <p style={{fontFamily:"Encode Sans Expanded"}}>Loading...</p>
)}
<div style={{
               
             
               marginLeft: '10px',
               marginBottom: '-30px',
               padding: '5px',
               background: 'white',
               backgroundColor: "rgba(255,255,255,0.9)",
               boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
               borderRadius: '5px',
               opacity: showTooltip2 ? 1 : 0,
               transition: 'opacity 0.3s ease-in-out',
               visibility: showTooltip2 ? 'visible' : 'hidden',
               fontFamily:"Encode Sans Expanded",
               whiteSpace:'nowrap',
               width:'120px'
           }}>
              Weagle Tokens
           </div>
        </div>
        
         </div>
      );
  }
}




  return (
    
    <MDBContainer style={{  width: isMobile ? '85%' : '95%', marginTop: isMobile ? '-200px' : '-332px'}} className="py-5">
      
           {mode && (
   <div style={{ overflowX: isMobile ? 'scroll' :'unset',backgroundColor: 'rgba(251,251,251,0.8)', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', position: 'sticky', top: '0', zIndex: '999' }}>
 

   <div style={{ display: 'flex', justifyContent: isMobile ? 'left' : 'center', width: '100%' }}>
   {/* <div  style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', marginBottom: '0px', flexDirection:'column' }}>
       <span style={{ textAlign:'center', fontSize: '24px', fontWeight: 'bold', marginRight: '0px', fontFamily: "Encode Sans Expanded", color:'#ff9900', fontWeight:'700' }}>weagle</span>
       <span style={{ fontFamily: "Encode Sans Expanded",textAlign:'center',fontSize: '18px', color: '#999' }}>Meta AI tools</span>
   </div> */}
       
       <div style={{fontWeight:'400', fontFamily: "Encode Sans Expanded",gap: '40px', justifyContent: 'center',display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        
     
  <div
      onClick={() => {
        if (Aimode !== 'ChatBase') {
          changeAiMode('ChatBase');
          changeAiModeText(ppps1segnal2); // Replace with the actual text or variable you want
        }
      }}
      style={{
        cursor: Aimode !== 'ChatBase' ? 'pointer' : 'default',
        gap: '0px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}
    >
       <i style={{ width: '27px', height: '24px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar1})`, backgroundSize: 'cover' }}></i>
       <span  style={{  color: chatBaseColor, whiteSpace:'nowrap', fontSize:'14px', fontWeight:'600',paddingBottom:'3px', borderBottom:chatBaseLine,}}>Chat</span>
       
       </div>
   

       <div 
  
   onClick={() => {
    if (Aimode !== 'analisiFinanziarieMod') {
      changeAiMode('analisiFinanziarieMod');
      changeAiModeText(ppps1segnal3); // Replace with the actual text or variable you want
    }
  }}
      style={{
        cursor: Aimode !== 'analisiFinanziarieMod' ? 'pointer' : 'default',
        gap: '0px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1
      }}
    >
      <i style={{
        width: '27px',
        height: '26px',
        marginBottom: '10px',
        backgroundImage: `url(${ImmagineToolbar3})`,
        backgroundSize: 'cover'
      }} />
      <span style={{
        paddingBottom:'3px',
        color: analisiFinanziarieColor,
        borderBottom:analisiFinanziarieLine,
        whiteSpace: 'nowrap',
        textAlign: 'center',
         fontSize:'14px'
         , fontWeight:'600'
      }}>
       Financial
      </span>
    </div>
       <div 
       
       onClick={() => {
        if (Aimode !== 'marketingMod') {
          changeAiMode('marketingMod');
          changeAiModeText(ppps1segnal4); // Replace with the actual text or variable you want
        }
      }}
       style={{ cursor: Aimode !== 'marketingMod' ? 'pointer' : 'default',gap: '0px', justifyContent: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
       <i style={{ width: '27px', height: '24px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar4})`, backgroundSize: 'cover' }}></i>
       <span  style={{ paddingBottom:'3px',  borderBottom: MarketingLine, color: MarketingColor, whiteSpace:'nowrap', textAlign:'center', fontSize:'14px', fontWeight:'600'}}>Marketing</span>
       
       </div>
       <div 
         onClick={() => {
          if (Aimode !== 'emailMod') {
            changeAiMode('emailMod');
            changeAiModeText(ppps1segnal5); // Replace with the actual text or variable you want
          }
        }}
       
       style={{ cursor: Aimode !== 'emailMod' ? 'pointer' : 'default',gap: '0px', justifyContent: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
       <i style={{ width: '27px', height: '26px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar5})`, backgroundSize: 'cover' }}></i>
       <span  style={{paddingBottom:'3px',borderBottom: emailLine, color: emailColor, whiteSpace:'nowrap', textAlign:'center', fontSize:'14px', fontWeight:'600'}}>Email</span>
       
       </div>
       <div 
           onClick={() => {
            if (Aimode !== 'documentsMod') {
              changeAiMode('documentsMod');
              changeAiModeText(ppps1segnal6); // Replace with the actual text or variable you want
            }
          }}
         
       style={{ cursor: Aimode !== 'documentsMod' ? 'pointer' : 'default',gap: '0px', justifyContent: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
       <i style={{ width: '27px', height: '28px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar6})`, backgroundSize: 'cover' }}></i>
       <span  style={{paddingBottom:'3px',borderBottom: documentsLine, color: documentsColor, whiteSpace:'nowrap', textAlign:'center', fontSize:'14px', fontWeight:'600'}}>Template</span>
       
       </div>
       <div 
        onClick={() => {
          if (Aimode !== 'webMod') {
            changeAiMode('webMod');
            changeAiModeText(ppps1segnal7); // Replace with the actual text or variable you want
          }
        }}
       style={{ cursor: Aimode !== 'webMod' ? 'pointer' : 'default',gap: '0px', justifyContent: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
       <i style={{ width: '27px', height: '26px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar7})`, backgroundSize: 'cover' }}></i>
       <span  style={{paddingBottom:'3px',borderBottom: webLine, color: webColor, whiteSpace:'nowrap', textAlign:'center', fontSize:'14px', fontWeight:'600'}}>Web Chat</span>
       
       </div>
       <div style={{display:'flex', flexDirection:'row', gap:'20px'}}>
       <div style={{gap: '0px', justifyContent: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
   
       <i         onClick={() => {
                              setMessages([]);
                              localStorage.removeItem('chatMessages'); // Clear the localStorage when resetting chat
                          }}style={{cursor:'pointer', width: '21px', height: '18px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar8})`, backgroundSize: 'cover' }}></i>
     
       
       </div>
       <div style={{gap: '0px', justifyContent: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
       <i  onClick={() => {
      setMessages([]);
      setInput('');
      setMode(null);
      setFirstInput(true); // assuming that `firstInput` controls the select mode button
      localStorage.removeItem('chatMessages');
      localStorage.removeItem('chatInput');
      localStorage.removeItem('chatMode');
    }} style={{ cursor:'pointer',width: '21px', height: '17px', marginBottom: '10px', backgroundImage: `url(${ImmagineToolbar9})`, backgroundSize: 'cover' }}></i>
     
       
       </div>
       </div>
       </div>
      
   </div>
   </div>
)}

    <MDBRow  className="d-flex justify-content-center">
        <MDBCol md="8" lg="6" xl="10">
          
        <MDBCard id="chat1" style={{marginTop:'100px', height:'100%',minHeight:'600px',borderRadius: "15px", boxShadow:'none', backgroundColor:'transparent', backgroundImage: 'linear-gradient(to left top, rgba(255, 229, 198, 0.0), rgba(255, 229, 198, 0.0), rgba(255, 229, 198, 0.0), rgba(255, 229, 198, 0.0), rgba(255, 229, 198, 0.0))'}}>
         
                <MDBCardHeader 
                    className="d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                    style={{
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                    }}
                >
                   
                    {/* {flagDropdown()} */}
                    <div style={{gap:'10px'}}className={"d-flex flex-row"}>
                        {/* <div onClick={resetChat} className="mb-0" style={{ cursor: "pointer" }}>
                            {loadingReset && "Resetting..."}
                            {!loadingReset && "Reset History"}
                        </div> */}
                  
 


    
                    </div>
                </MDBCardHeader>

                <div>
    {/* Heading */}
    {!mode && (
      <div>

      
        <h1 style={{ lineHeight:'40px',fontFamily: "Encode Sans Expanded", fontSize: '30px', textAlign: 'center', marginBottom: '50px', color:'rgba(1,1,1,0.7)', fontWeight:'500' }}>
       {ppps1segnal9}<br></br> 
        </h1>
        <h1 style={{ fontFamily: "Encode Sans Expanded", fontSize: '20px', textAlign: 'center', marginBottom: '20px', color:'rgba(1,1,1,1)' }}>
        {ppps1segnal10}
        </h1>
        <div style={{display:'flex', justifyContent:'center', gap:'20px'}} className='divmodalChat'>

        {isMobile && 
                <div style={{opacity: opacity, transition: 'opacity 0.5s', fontFamily: "Encode Sans Expanded", fontSize: '20px', borderRadius:'30px',padding:'20px',width:'250px', height:'200px', backgroundColor:'rgba(255,255,255,0.3)', display: 'flex',flexDirection:'column', justifyContent:'flex-start', textAlign:'center' }}>
                    {slides[currentIndex]}
                </div>
            }
        <div 
    className='divmodalChatc' 
    style={{ 
        display: isMobile ? 'none' : 'flex',
        fontFamily: "Encode Sans Expanded", 
        fontSize: '20px', 
        borderRadius:'30px',
        padding:'20px',
        width:'250px', 
        height:'200px', 
        backgroundColor:'rgba(255,255,255,0.3)', 
        flexDirection:'column', 
        justifyContent:'flex-start', 
        textAlign:'center' 
    }}
>
{ppps1segnal11} 
           
</div>
<div 
    className='divmodalChatc' 
    style={{ 
        display: isMobile ? 'none' : 'flex',
        fontFamily: "Encode Sans Expanded", 
        fontSize: '20px', 
        borderRadius:'30px',
        padding:'20px',
        width:'250px', 
        height:'200px', 
        backgroundColor:'rgba(255,255,255,0.3)', 
        flexDirection:'column', 
        justifyContent:'flex-start', 
        textAlign:'center' 
    }}
>
{ppps1segnal12}
           
</div>
<div 
    className='divmodalChatc' 
    style={{ 
        display: isMobile ? 'none' : 'flex',
        fontFamily: "Encode Sans Expanded", 
        fontSize: '20px', 
        borderRadius:'30px',
        padding:'20px',
        width:'250px', 
        height:'200px', 
        backgroundColor:'rgba(255,255,255,0.3)', 
        flexDirection:'column', 
        justifyContent:'flex-start', 
        textAlign:'center' 
    }}
>
{ppps1segnal13}
</div>


        </div>
        </div>
    )}

    {/* Input at the top if no mode is selected */}
    {/* {!mode && (
        <div>
    <div style={{ position: 'relative' }}>
    <MDBTextArea
        onChange={(e) => {
            setInput(e.target.value);
        }}
        value={input}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                if (firstInput && !mode) {
                    // Automatically select "middle" as mode
                    handleModeSelection('middle');
                } else {
                    getCorrectSentence(input);
                }
            }
        }}
        style={{
            height: '60px',
            resize: 'none',
            borderRadius: '10px',
            paddingRight: '40px', // Adjust padding for the icon
            fontSize: '14px',
            lineHeight: '1.5',
            backgroundColor:'white'
        }}
        className="form-outline"
        label="Ask me something..."
        id="textAreaExample"
        rows={1}
    />
    <img
        src={SendIcon}
        alt="Icon"
        style={{
            position: 'absolute',
            top: '50%',
            right: '10px', // Position the icon on the right side
            transform: 'translateY(-50%)',
            width: '20px',
            height: '20px',
        }}
    />
</div>


        </div>
    )} */}
  

    {/* Mode Selection */}
    {firstInput && !mode && (
        <ModeSelection
            onSelect={(selectedMode) => {
                handleModeSelection(selectedMode);
            }}
        />
    )}
  {/* {!mode && (
       
    )} */}
    {/* Messages and input (at the bottom) once mode is selected */}
    {mode && (
        <>
            <Received isDetail={true} message={AimodeText} />

            {messages?.length > 0 &&
                messages?.map((message, index) => {
                    if (message?.type === 'sent') {
                        return <Sent message={message?.text} />;
                    } else {
                        return (
                          <div>
                            <Received
                isDetail={message?.detail}
                handleMessageDetail={getMessageDetail}
                allMessages={messages}
                index={index}
                message={message?.text}
                key={index}
                animation={index === messages.length - 1}
              />
                            <div ref={messagesEndRef} />
                            </div>
                        );
                    }
                })}

            {loading && <Loader />}

            <div className='form-outline'style={{ position: 'relative',    marginTop:    isMobile ? '10vh' :'35vh',}}>
            <div 
            onClick={toggleUploadModal} 
            onMouseEnter={() => setShowTooltip(true)} 
            onMouseLeave={() => setShowTooltip(false)}
            style={{
                cursor: 'pointer', 
                gap: '0px', 
                justifyContent: 'left',
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                flex: 1,
                marginLeft:    isMobile ? '-13%' :'-50px',
                marginBottom: '-67px',
                position: 'relative',
                width:'50px'
            }}
        >
            <i style={{ 
                width: '40px', 
                height: '40px', 
                marginBottom: '0px', 
                backgroundImage: `url(${ImmagineToolbar2})`, 
                backgroundSize: 'cover' 
            }}></i>
            <div style={{
               
                marginBottom: '-100px', // Adjust as needed to position below the icon
                marginLeft: '-80px',
               
                padding: '5px',
                background: 'white',
                backgroundColor: "rgba(255,255,255,0.9)",
                boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: '5px',
                opacity: showTooltip ? 1 : 0,
                transition: 'opacity 0.3s ease-in-out',
                visibility: showTooltip ? 'visible' : 'hidden',
                fontFamily:"Encode Sans Expanded"
            }}>
                Upload docs
            </div>
        </div>
       {uploadModal && (
  <div style={{ fontFamily: "Encode Sans Expanded", position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
  <div 
    style={{ borderRadius:'12px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000 }}
    onDrop={onDrop}
    onDragOver={onDragOver}
  >
      <h2 style={{ fontFamily:"Encode Sans Expanded"}}>Upload Document</h2>
      {!selectedFile && (
  <>
    <label style={{ fontFamily:"Encode Sans Expanded", display: 'block', marginBottom: '10px' }}>
      <p>Privacy Filter</p>
      <div 
    style={{
      display: 'inline-block',
      width: '40px',
      height: '20px',
      background: anonymizeToggle ? 'lightgreen' : 'lightgray',
      borderRadius: '20px',
      position: 'relative',
      cursor: 'pointer',
      fontFamily: "Encode Sans Expanded"
    }} 
    onClick={() => setAnonymizeToggle(!anonymizeToggle)}
  >
        <div 
          style={{
            position: 'absolute',
            top: '2px',
            left: anonymizeToggle ? '22px' : '2px',
            width: '16px',
            height: '16px',
            background: 'white',
            borderRadius: '50%',
            transition: 'left 0.2s',
            fontFamily: "Encode Sans Expanded"
          }}
        ></div>
      </div>
    </label>
    <input 
      type="file"
      onChange={handleFileSelect}
      accept=".pdf,.doc,.docx,.xls,.xlsx"
      style={{ 
        display: 'block', 
        marginBottom: '10px',  
        color: '#0E86D4',
        backgroundColor: "#fff",
        padding: '7px', 
        fontFamily: "Encode Sans Expanded",
        fontWeight: 'bold',
        fontSize: '12px',
        textAlign: 'center'
      }}
    />
  </>
)}
      {selectedFile && (
   <>
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
     <img
       src={selectedFile.type === 'application/pdf' ? 'https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png' : 'https://icons.veryicon.com/png/o/file-type/file-type-1/doc-icon.png'}
       alt={selectedFile.type === 'application/pdf' ? 'PDF Icon' : 'DOC Icon'}
       style={{ width: '20px', height: '20px', marginRight: '10px' }}
Selected File     />
     <p style={{marginBottom:0}}>{selectedFile.name}</p>
   </div>
   <button style={{ borderRadius: "15px",
          color:'#0E86D4',
          backgroundColor: "#fff",
          padding:'7px',
          marginTop:'10px',
          width:'130px',
          fontFamily:"Encode Sans Expanded",
          fontWeight:'bold',
          fontSize:'12px',
          textAlign:'center',
          cursor:'pointer',
          borderWidth:'1px',
          borderColor:'#0E86D4',
          borderStyle:'solid',
          marginRight:'105px'
        }}  onClick={clearFileData}>Clear File</button>
 </>
      )}
      <button style={{ borderRadius: "15px",
          color:'#0E86D4',
          backgroundColor: "#fff",
          padding:'7px',
          marginTop:'10px',
          width:'130px',
          fontFamily:"Encode Sans Expanded",
          fontWeight:'bold',
          fontSize:'12px',
          textAlign:'center',
          cursor:'pointer',
          borderWidth:'1px',
          borderColor:'#0E86D4',
          borderStyle:'solid',
        }} onClick={() => setUploadModal(false)}>{ppps1segnal14}</button>
    </div>
  </div>
)}
 {renderTextAreaOrErrorMessage()}

  
      {/* Add toggle switch for voice generation */}
      {selectedFile && (
   <>
   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
     <img
       src={selectedFile.type === 'application/pdf' ? 'https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png' : 'https://icons.veryicon.com/png/o/file-type/file-type-1/doc-icon.png'}
       alt={selectedFile.type === 'application/pdf' ? 'PDF Icon' : 'DOC Icon'}
       style={{ width: '20px', height: '20px', marginRight: '10px' }}
Selected File     />
     <p style={{marginBottom:0}}>{selectedFile.name}</p>
   </div>
   <button style={{ borderRadius: "15px",
          color:'#ff9900',
          backgroundColor: "#fff",
          padding:'7px',
          marginTop:'10px',
          width:'130px',
          fontFamily:"Encode Sans Expanded",
          fontWeight:'bold',
          fontSize:'12px',
          textAlign:'center',
          cursor:'pointer',
          borderWidth:'1px',
          borderColor:'#ff9900',
          borderStyle:'solid',
        }} onClick={clearFileData}>Clear File</button>
 </>
      )}
      {/* <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
        <label style={{ marginRight: "10px", fontFamily:"Encode Sans Expanded" }}>AI voice generation:</label>
        <input
          type="checkbox"
          checked={generateVoice}
          onChange={(e) => setGenerateVoice(e.target.checked)}
        />
      </div> */}
      
     
                  <label style={{ display:'flex', flexDirection:'column',textAlign:'left', fontFamily:"Encode Sans Expanded", fontSize:'15px' }}>{ppps1segnal8}</label>
            </div>
        </>
    )}
</div>





            </MDBCard>
        </MDBCol>
    </MDBRow>
</MDBContainer>

  );
}
