//export const baseURL = "http://localhost:3001";
// export const baseURL = "https://weagle-backend.onrender.com";
export const baseURL = 'https://as-weagle-test-weu-001.azurewebsites.net';

export const endPoints = {
  authSignUp: baseURL + "/signup",
  authSignIn: baseURL + "/login",
  authUpdateUserType: baseURL + "/updateUserType",
  getUserData: baseURL + "/getUserData",
  addFolder: baseURL + "/addFolder",
  addReceipt: baseURL +"/addReceipt",
  getUserReceipt: baseURL + "/getUserReceipt",
  getFolder: baseURL + "/getFolders",
  renameFolder: baseURL + "/renameFolder",
  deleteFolder: baseURL + "/deleteFolder",
  addArticleToFolder: baseURL + "/addArticleToFolder",
  deleteArticleFromFolder: baseURL + "/deleteArticleFromFolder",
  saveSource: baseURL + "/saveSource",
  getSource: baseURL + "/getSource",
  getSourceFeeds: baseURL + "/getSourceFeeds",
  deleteSource: baseURL + "/removeSource",
  addArticlesToPortafolioDati: baseURL + "/addToPortafolioDati",
  getArticlesFromPortafolioDati: baseURL + "/getPortafolioDati",
  deleteArticlesFromPortafolioDati: baseURL + "/removeFromPortafolioDati",
  updateProfile: baseURL + "/updateProfile",
  saveCompleteProfile: baseURL + "/saveCompleteProfile",
  validateToken: baseURL + "/validateToken",
  getArticlesFromWeb: (q, type, page, locale) => baseURL + `/webSearch?q=${q}&engine=${type}&page=${page}&locale=${locale}`,
  getCoupons: baseURL + "/getCoupons",
  saveUserRequest: baseURL + "/saveUserRequest",
  sendVerificationCode: baseURL + "/sendVerificationCode",
  verifyCode: baseURL + "/verifyCode",
  changePassword: baseURL + "/changePassword",
  getSurveys: baseURL + "/getSurvey",
  saveSurveyResponse: baseURL + "/saveSurveyResponse",
  getSurveyResponseAndCompleteProfile:
    baseURL + "/getSurveyResponseAndCompleteProfile",
  saveSupport: baseURL + "/saveSupport",
  addSell: baseURL + "/addSell",
  getNews: baseURL + "/getNews",
  getSearchFeed: baseURL + "/searchFeed",
  pay: baseURL + "/pay",
  inviteUsers: baseURL + "/inviteUsers",
  verifyInvite: baseURL + "/verifyInvite",
  updateUser: baseURL + "/updateUser",
  saveFolderInvite: baseURL + "/saveFolderInvite",
  actionFolderInvite: baseURL + "/actionFolderInvite",
  platformLogin: baseURL + "/handlePlatformLogin",
  sessionValidation: baseURL + "/sessionValidation",
  fetchCisoUserDetails: baseURL + "/fetchCisoUserDetails",
  createGroupsAndAccounts: baseURL + "/createGroupsAndAccounts",
  deleteGroups: baseURL + "/deleteGroups",
  createGroup: baseURL + "/createGroup",
  createAccounts: baseURL + "/createAccounts",
  changeChildMailPassword: baseURL + "/changeChildMailPassword",
  deleteAccounts: baseURL + "/deleteAccounts",
  handleLoginAzure: baseURL + "/handleLoginAzure",
  changeGroup: baseURL + "/changeGroup", 
  privacyManagement: baseURL + "/privacyManagement", 
  attemptedAccess: baseURL + "/attemptedAccess", 
  fetchCiso: baseURL + "/fetchCiso", 
  platformLogout: baseURL + "/handlePlatformLogout", 
  
};
