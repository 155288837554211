import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./search-bar1.css";
import { SearchContext } from "../context/search_context";

const SearchBar1 = (props) => {
  const { searchValue, setSearchValue, onSubmitSearch, loader } =
    useContext(SearchContext);
  const { searchTerm } = props; // Get the searchTerm prop
    const SubmitSearchBar2 = () => {
      onSubmitSearch();
      history.push(`/power-search/${searchValue}`);
    };
    const handleReset = () => {
      setSearchValue("");
    };  


    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // Notify the parent component (MapComponent) that Enter key was pressed
        props.onEnterPress(searchValue);
      }
    };

    const translations = {
      en: {
        'cerca': 'Search...',
      },
      it: {
  
        'cerca': 'Cerca...',
        
      },
    };
    function translate(key, lang) {
      const italianTranslations = translations.it;
    
      if (lang === 'it' && italianTranslations[key]) {
        return italianTranslations[key];
      }
     
      return translations.en[key];
    }
    const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
    const text01Search1 = translate('cerca', lang);
  return (
    <div className={`search-bar1-container ${props.rootClassName} `}>
      <div className="search-bar1-search-bar-main-div">
        <div className="search-bar1-search-div">
          <span className="search-bar1-searc-text">
            {/*<span className="">Cerca</span>*/}
            <input
  disabled={loader}
  value={searchValue}
  onKeyPress={(e) => {
    if (e.key === "Enter") {
      onSubmitSearch();
    }
  }}
  onChange={(event) => setSearchValue(event.target.value)}
  className={"search-input"}
  type="text"
  placeholder={text01Search1}
/>

          </span>
        </div>
        <div className="search-bar1-delete-search-div">
        <img
            alt={props.image_alt}
            src={props.image_src}
            className="search-bar1-delete-icon"
            onClick={handleReset}
          />
          <div onClick={SubmitSearchBar2} className="search-bar1-search-icon-div">
            <img
              alt={props.image_alt1}
              src={props.image_src1}
              className="search-bar1-search-icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBar1.defaultProps = {
  image_src: "/playground_assets/backspace-delete-button%201.svg",
  rootClassName: "",
  image_alt1: "image",
  image_src1: "/playground_assets/group%202.svg",
  image_alt: "image",
};

SearchBar1.propTypes = {
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt: PropTypes.string,
};

export default SearchBar1;
